import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import AlertMessage from './AlertMessage';
import Axios from 'axios';
import checkUserProfile from '../../utils/checkUserProfile';
import getSvg from '../../utils/getSvg';
import Header from './Header'


export default function ShareContacts() {
    const navigate = useNavigate();
    const {userId, type, packageName} = useParams();
    const [myContacts, setMyContacts] = useState(['']);
    const [buttonTitle, setButtonTitle] = useState('Send Invitations');
    const [disableButton, setDisableButton] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [showAlertMessageAddEmail, setShowAlertMessageAddEmail] = useState(false);
    const [showDuplicatedEmailsMsg, setShowDuplicatedEmailsMsg] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showNoContacts, setShowNoContacts] = useState(false);
    const [sendingInvitation, setSendingInvitation] = useState(false);
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showWrongEmails, setShowWrongEmails] = useState(false);
    const [wrongEmails, setWrongEmails] = useState([]);
    const [emailsSent, setEmailsSent] = useState([]);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const userType = parseInt(sessionStorage.getItem('userType'));
    const userTypeToBeChecked = parseInt(process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED);
    sessionStorage.setItem('userType', userTypeToBeChecked);
    const handleGoToLogin = () => navigate('/login');
    let message = "Invite surveyors and get a commission";
    if (Number(type) === 500002) {
        message = "Invite Pros & Vendors to your network";
    }
    useEffect(() => {
        checkLogin()
    }, []);
    const checkLogin = () => {
        if (isLoggedIn === 'true') {
            try {
                getUserProfile(userId, userType, userTypeToBeChecked);
            } catch (err) {
                console.log('error trying to get the package id = ', err);
            }
            ;
        } else {
            handleGoToLogin();
        }
    }

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const {isGoodToGo} = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (!isGoodToGo) {
            setShowNotAllowed(true);
        }
    }

    const addEmailField = () => {
        setWrongEmails([]);
        setShowAlertMessageAddEmail(false);
        setShowSuccessMessage(false);
        let hasBadEmail = false;
        myContacts.forEach((email) => {
            if (email.trim().length === 0) {
                hasBadEmail = true;
            }
        });
        if (hasBadEmail) {
            setShowNoContacts(true);
            return;
        }
        myContacts.forEach((email) => {
            const matchEmail = email.match(/^[\w.-]+@[\w.-]+\.[\w]{2,}$/i);
            if (Array.isArray(matchEmail)) {
                setMyContacts([...myContacts, '']); // Add a new input field only if the email is valid
            } else {
                setWrongEmails([...wrongEmails, email]);
                setShowAlertMessageAddEmail(true);
            }
        });
    };

    // Function to handle changes in email input fields
    const handleEmailChange = (index, value) => {
        setDisableButton(false);
        const updatedEmails = [...myContacts];
        updatedEmails[index] = value;
        setMyContacts(updatedEmails);
    };

    const shareContacts = (type) => {
        if (!showDuplicatedEmailsMsg) {
            setShowSuccessMessage(false);
            setShowAlertMessage(false);
            setShowAlertMessageAddEmail(false);
            setShowNoContacts(false);
            if (!sendingInvitation && userId !== '') {
                const goodEmails = [];
                myContacts.forEach((email) => {
                    if (email.trim().length > 0) {
                        const matchEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                        if (Array.isArray(matchEmail) && matchEmail.length > 0) {
                            goodEmails.push(email);
                        }
                    }
                });

                if (goodEmails.length > 0 && userId !== '') {
                    setDisableButton(true);
                    setSendingInvitation(true);
                    setButtonTitle('Sending Invitations...');
                    Axios.post(`${process.env.REACT_APP_LOCALHOST}/shareContacts`, {
                        userId: userId,
                        contacts: goodEmails,
                        userType: type,
                        packageName: packageName
                    }).then((response) => {
                        if (response.data?.messages?.length > 0) {
                            const containsError = response.data.messages.some(message => message.includes('error'));
                            setDisableButton(false);
                            if (containsError) {
                                console.log('Error sending emails');
                            } else {
                                setShowSuccessMessage(true);
                            }
                        }
                        setButtonTitle('Send Invitations');
                        setShowSuccessMessage(true);
                        setSendingInvitation(false);
                        setMyContacts(['']);
                    }).catch(function (error) {
                        setDisableButton(false);
                        setShowNoContacts(false);
                        setSendingInvitation(false);
                        setButtonTitle('Send Invitations');
                        setShowSuccessMessage(false);
                    })
                } else {
                    setShowAlertMessage(true);
                }
                setEmailsSent(goodEmails);
            } else {
                setDisableButton(false);
                setSendingInvitation(false);
                setShowAlertMessage(true);
            }
        }
    }

    const checkForDuplicates = (email, index) => {
        const updatedEmails = [...myContacts];
        updatedEmails[index] = email;
        setMyContacts(updatedEmails);
        const filteredEmails = myContacts.filter((item) => item !== '');
        const hasDuplicates = (new Set(filteredEmails)).size !== filteredEmails.length;
        if (hasDuplicates) {
            setDisableButton(true);
            setShowDuplicatedEmailsMsg(true);
        } else {
            setDisableButton(false);
            setShowDuplicatedEmailsMsg(false);
        }
    }

    const closeDuplicatedMsg = () => {
        setShowDuplicatedEmailsMsg(false);
        setShowAlertMessage(false);
        setShowNoContacts(false);
        setShowAlertMessageAddEmail(false);
        setShowSuccessMessage(false);
    };

    return (
        <div id="fazzad-site-container">
            <Header/>
            {showNotAllowed ?
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 warning">You are not allowed to see this page.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => navigate('/')}>
                                {getSvg('close')}
                            </button>
                        </div>
                    </div>
                </div>
                : ''
            }
            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <li>
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/survey`)
                                }}>Home</a>
                                <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </li>
                            <li aria-current="page">
                                <span>
                                    {Number(type) === 500001 ? "Invite Surveyors" : "Invite Pros & Vendors"}
                                </span>
                            </li>
                        </ol>
                    </nav>
                    {showSuccessMessage ?
                        <div className="alert-container">
                            <div className="alert alert--success">
                                <div className="alert__content">
                                    <p className="alert__heading">Invites sent successfully</p>
                                    <p className="alert__message">Feel free to invite more contacts or <a href="/"
                                                                                                          onClick={(e) => {
                                                                                                              e.preventDefault();
                                                                                                              navigate(`/survey`)
                                                                                                          }}>return to
                                        your home feed</a>.</p>
                                </div>
                                <button type="button" className="button-tertiary button-s button-icon alert__close"
                                        onClick={() => setShowSuccessMessage(false)}>
                                    {getSvg('close')}
                                </button>
                            </div>
                        </div>
                        : ''
                    }
                    <div id="page-title">
                        <h1 className="h3">{message}</h1>
                        {type === '500001' ?

                            <>
                                <video controls width='100%' poster={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/thumbnails/photo-00-surveyor-invite.png'} height='auto'>
                                    <source
                                        src={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Surveyor+-+Invite.mp4'}
                                        type='video/mp4'/>
                                    <p>Your browser does not support the video element.</p>
                                </video>
                            </>

                            // <>
                            //     <p>Invite Architectural As-built Surveyors to the platform and get $100 when they sign
                            //         up!</p>
                            //     <p>Tip: Architects, Engineers and CAD Technicians who are experts in AutoCAD make for
                            //         great Architectural As-built Surveyors. Invite them all and get more money!</p>
                            //
                            //     <svg width="200" height="192" viewBox="0 0 200 192" fill="none"
                            //          xmlns="http://www.w3.org/2000/svg">
                            //         <g clip-path="url(#clip0_8957_9381)">
                            //             <path
                            //                 d="M58.5645 191.563C58.5645 191.805 58.7588 192 59.0007 192H199.512C199.754 192 199.949 191.805 199.949 191.563C199.949 191.321 199.754 191.127 199.512 191.127H59.0007C58.7588 191.127 58.5645 191.321 58.5645 191.563Z"
                            //                 fill="#CCCCCC"/>
                            //             <path
                            //                 d="M79.8857 191.814H15.2095C7.32338 191.814 0.907471 185.395 0.907471 177.505V38.4478C0.907471 30.558 7.32338 24.1392 15.2095 24.1392H79.8857C87.7717 24.1392 94.1876 30.558 94.1876 38.4478V177.505C94.1876 185.395 87.7717 191.814 79.8857 191.814Z"
                            //                 fill="#3F3D56"/>
                            //             <path
                            //                 d="M94.1878 75.2891C93.8541 75.2891 93.5828 75.5605 93.5828 75.8944V95.2647C93.5828 95.5986 93.8541 95.8701 94.1878 95.8701C94.5215 95.8701 94.7928 95.5986 94.7928 95.2647V75.8944C94.7928 75.5605 94.5215 75.2891 94.1878 75.2891Z"
                            //                 fill="#3F3D56"/>
                            //             <path
                            //                 d="M92.8265 38.4479V177.503C92.8265 184.37 87.4839 189.988 80.7286 190.423H80.7255C80.6257 190.429 80.5259 190.436 80.423 190.439C80.2445 190.448 80.0661 190.451 79.8845 190.451C79.8845 190.451 79.219 190.402 78.0028 190.311C76.7746 190.221 74.9867 190.084 72.7571 189.906C72.0885 189.854 71.3836 189.797 70.6394 189.736C69.1571 189.615 67.5295 189.482 65.7839 189.331C65.0881 189.273 64.3681 189.21 63.633 189.146C59.8757 188.822 55.6676 188.444 51.2296 188.014C50.4793 187.945 49.723 187.872 48.9606 187.796C48.761 187.775 48.5583 187.757 48.3556 187.736C45.1912 187.424 2.26904 179.125 2.26904 177.503V38.4479C2.26904 31.296 8.06236 25.5 15.211 25.5H22.9374C24.1717 25.5 25.164 26.4625 25.2699 27.6943C25.2789 27.776 25.288 27.8577 25.3031 27.9395C25.527 29.1773 26.6615 30.0399 27.92 30.0399H67.1756C68.434 30.0399 69.5685 29.1773 69.7924 27.9395C69.8075 27.8577 69.8166 27.776 69.8257 27.6943C69.9315 26.4625 70.9238 25.5 72.1581 25.5H79.8845C87.0331 25.5 92.8265 31.296 92.8265 38.4479Z"
                            //                 fill="white"/>
                            //             <path
                            //                 d="M0.605045 60.7612C0.271353 60.7612 0 61.0327 0 61.3666V66.2091C0 66.543 0.271356 66.8145 0.605045 66.8145C0.938733 66.8145 1.21009 66.543 1.21009 66.2091V61.3666C1.21009 61.0327 0.938733 60.7612 0.605045 60.7612Z"
                            //                 fill="#3F3D56"/>
                            //             <path
                            //                 d="M0.605045 75.2891C0.271353 75.2891 0 75.5605 0 75.8944V85.2769C0 85.6107 0.271356 85.8822 0.605045 85.8822C0.938733 85.8822 1.21009 85.6107 1.21009 85.2769V75.8944C1.21009 75.5605 0.938733 75.2891 0.605045 75.2891Z"
                            //                 fill="#3F3D56"/>
                            //             <path
                            //                 d="M0.605045 88.606C0.271353 88.606 0 88.8774 0 89.2113V98.5938C0 98.9276 0.271356 99.1991 0.605045 99.1991C0.938733 99.1991 1.21009 98.9276 1.21009 98.5938V89.2113C1.21009 88.8774 0.938733 88.606 0.605045 88.606Z"
                            //                 fill="#3F3D56"/>
                            //             <path
                            //                 d="M22.7267 27.6196H11.1557C11.0929 27.6196 11.042 27.6706 11.042 27.7334V29.0192C11.042 29.082 11.0929 29.1329 11.1557 29.1329H22.7267C22.7895 29.1329 22.8404 29.082 22.8404 29.0192V27.7334C22.8404 27.6706 22.7895 27.6196 22.7267 27.6196Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M74.07 29.1332C74.7383 29.1332 75.28 28.5912 75.28 27.9226C75.28 27.2539 74.7383 26.7119 74.07 26.7119C73.4016 26.7119 72.8599 27.2539 72.8599 27.9226C72.8599 28.5912 73.4016 29.1332 74.07 29.1332Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M77.3976 29.1332C78.0659 29.1332 78.6077 28.5912 78.6077 27.9226C78.6077 27.2539 78.0659 26.7119 77.3976 26.7119C76.7293 26.7119 76.1875 27.2539 76.1875 27.9226C76.1875 28.5912 76.7293 29.1332 77.3976 29.1332Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M80.7255 29.1332C81.3938 29.1332 81.9356 28.5912 81.9356 27.9226C81.9356 27.2539 81.3938 26.7119 80.7255 26.7119C80.0572 26.7119 79.5154 27.2539 79.5154 27.9226C79.5154 28.5912 80.0572 29.1332 80.7255 29.1332Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M73.058 109.484H21.7349C20.9264 109.484 20.2686 108.826 20.2686 108.017C20.2686 107.208 20.9264 106.55 21.7349 106.55H73.058C73.8666 106.55 74.5244 107.208 74.5244 108.017C74.5244 108.826 73.8666 109.484 73.058 109.484Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M47.5805 166.332C59.3235 166.332 68.843 156.809 68.843 145.06C68.843 133.312 59.3235 123.788 47.5805 123.788C35.8376 123.788 26.3181 133.312 26.3181 145.06C26.3181 156.809 35.8376 166.332 47.5805 166.332Z"
                            //                 fill="#25976E"/>
                            //             <path
                            //                 d="M50.9596 138.115C49.1652 138.188 47.1366 138.312 45.8103 139.689C44.658 140.886 44.4055 142.662 45.574 143.94C46.7455 145.221 48.5671 145.394 49.9175 146.393C51.5032 147.567 51.271 149.888 49.7365 151C47.927 152.312 45.5906 151.701 43.9363 150.453C43.5124 150.133 43.1009 150.849 43.52 151.165C45.0022 152.283 46.9362 152.906 48.7687 152.371C50.4226 151.888 51.7046 150.539 51.835 148.784C51.8973 147.946 51.6592 147.096 51.1387 146.435C50.5891 145.737 49.7976 145.307 48.9975 144.958C47.6201 144.356 45.2484 143.578 45.6489 141.619C46.1343 139.245 49.023 139.019 50.9597 138.94C51.4886 138.918 51.4913 138.093 50.9596 138.115Z"
                            //                 fill="white"/>
                            //             <path
                            //                 d="M47.6309 136.991L47.5518 155.107C47.5494 155.638 48.3751 155.639 48.3774 155.107L48.4565 136.991C48.4589 136.46 47.6332 136.46 47.6309 136.991Z"
                            //                 fill="white"/>
                            //             <path
                            //                 d="M160.233 0H55.4018C51.5563 0 48.4292 3.12847 48.4292 6.97579V78.8464C48.4292 82.6938 51.5563 85.8223 55.4018 85.8223H160.233C164.078 85.8223 167.206 82.6938 167.206 78.8464V6.97579C167.206 3.12847 164.078 0 160.233 0Z"
                            //                 fill="white"/>
                            //             <path
                            //                 d="M107.335 42.7335C113.814 42.7335 119.066 37.4789 119.066 30.9971C119.066 24.5153 113.814 19.2607 107.335 19.2607C100.856 19.2607 95.6042 24.5153 95.6042 30.9971C95.6042 37.4789 100.856 42.7335 107.335 42.7335Z"
                            //                 fill="#25976E"/>
                            //             <path
                            //                 d="M109.56 25.7601C108.108 25.8199 106.474 25.912 105.375 26.9932C104.465 27.8887 104.147 29.2829 104.983 30.3373C105.864 31.4475 107.328 31.6582 108.477 32.3676C109.191 32.8086 109.664 33.4766 109.68 34.335C109.694 35.1388 109.323 35.8897 108.703 36.3934C107.183 37.6293 105.109 37.1177 103.67 36.0368C103.387 35.8242 103.113 36.3016 103.393 36.5118C104.584 37.407 106.118 37.9269 107.601 37.5418C108.966 37.1872 110.065 36.1132 110.213 34.678C110.369 33.1654 109.376 32.1355 108.089 31.5446C106.912 31.0045 104.848 30.4291 105.043 28.7512C105.29 26.6179 107.901 26.3786 109.56 26.3102C109.913 26.2957 109.915 25.7454 109.56 25.7601Z"
                            //                 fill="white"/>
                            //             <path
                            //                 d="M107.037 24.7623L106.971 39.8575C106.97 40.2116 107.52 40.212 107.522 39.8575L107.588 24.7623C107.589 24.4082 107.039 24.4078 107.037 24.7623Z"
                            //                 fill="white"/>
                            //             <path
                            //                 d="M160.233 0H55.4018C51.5563 0 48.4292 3.12847 48.4292 6.97579V78.8464C48.4292 82.6938 51.5563 85.8223 55.4018 85.8223H160.233C164.078 85.8223 167.206 82.6938 167.206 78.8464V6.97579C167.206 3.12847 164.078 0 160.233 0ZM166.472 78.8464C166.472 82.2903 163.675 85.0887 160.233 85.0887H55.4018C51.9595 85.0887 49.1624 82.2903 49.1624 78.8464V6.97579C49.1624 3.53192 51.9595 0.733524 55.4018 0.733524H160.233C163.675 0.733524 166.472 3.53192 166.472 6.97579V78.8464Z"
                            //                 fill="#3F3D56"/>
                            //             <path
                            //                 d="M95.7307 7.09668H85.4441C84.9272 7.09668 84.5056 7.51846 84.5056 8.03559C84.5056 8.55638 84.9272 8.97817 85.4441 8.97817H95.7307C96.2476 8.97817 96.6692 8.55639 96.6692 8.03559C96.6692 7.51847 96.2476 7.09668 95.7307 7.09668Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M112.961 7.09668H102.674C102.157 7.09668 101.736 7.51846 101.736 8.03559C101.736 8.55638 102.157 8.97817 102.674 8.97817H112.961C113.478 8.97817 113.899 8.55639 113.899 8.03559C113.899 7.51847 113.478 7.09668 112.961 7.09668Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M130.19 7.09668H119.904C119.387 7.09668 118.965 7.51846 118.965 8.03559C118.965 8.55638 119.387 8.97817 119.904 8.97817H130.19C130.707 8.97817 131.129 8.55639 131.129 8.03559C131.129 7.51847 130.707 7.09668 130.19 7.09668Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M147.156 49.3354H68.4781C65.2044 49.3354 62.543 51.9981 62.543 55.2733V60.6354C62.543 63.9106 65.2044 66.5733 68.4781 66.5733H147.156C150.43 66.5733 153.092 63.9106 153.092 60.6354V55.2733C153.092 51.9981 150.43 49.3354 147.156 49.3354Z"
                            //                 fill="#25976E"/>
                            //             <path
                            //                 d="M26.8271 77.5669L23.8101 76.1371C25.0475 77.8648 26.1138 80.5632 26.6847 82.7104C27.6512 80.7103 29.2093 78.263 30.7516 76.8011L27.5629 77.6218C29.5277 67.9877 36.918 61.0714 45.3809 61.0714V60.3379C36.5412 60.3379 28.8333 67.5439 26.8271 77.5669Z"
                            //                 fill="#3F3D56"/>
                            //             <path
                            //                 d="M40.7133 92.2466H21.6505C20.8419 92.2466 20.1841 91.5885 20.1841 90.7795C20.1841 89.9706 20.8419 89.3125 21.6505 89.3125H40.7133C41.5219 89.3125 42.1797 89.9706 42.1797 90.7795C42.1797 91.5885 41.5219 92.2466 40.7133 92.2466Z"
                            //                 fill="#E6E6E6"/>
                            //             <path
                            //                 d="M163.843 91.3297L153.48 183.656C153.415 184.438 148.169 184.862 148.201 184.145L148.462 90.2213C148.462 89.4063 149.212 88.7542 150.026 88.8194L162.702 89.8953C163.354 89.9931 163.875 90.5799 163.843 91.3297Z"
                            //                 fill="#D48460"/>
                            //             <path
                            //                 d="M174.596 135.666L169.448 183.655C169.382 184.437 164.169 184.861 164.169 184.144L163.94 90.2204C163.94 89.438 174.629 134.949 174.596 135.666Z"
                            //                 fill="#D48460"/>
                            //             <path
                            //                 d="M170.001 183.232L170.392 185.318C170.816 186.133 176.942 188.872 176.942 188.872C177.398 189.035 177.952 189.524 177.985 190.046C178.017 190.73 177.268 191.284 176.681 191.284H164.559C163.451 191.284 162.604 190.111 162.832 188.905L163.842 183.265H170.001V183.232Z"
                            //                 fill="#294366"/>
                            //             <path
                            //                 d="M153.644 183.231C153.644 183.231 153.09 187.371 153.383 188.61C153.709 189.849 153.839 191.316 150.841 191.316C147.843 191.316 143.998 191.316 143.998 191.316C143.998 191.316 142.075 189.816 143.835 188.317L145.139 188.154L147.387 183.133L153.644 183.231Z"
                            //                 fill="#294366"/>
                            //             <path
                            //                 d="M156.641 27.0727C156.641 27.0727 151.656 34.9621 147.582 37.2768C142.629 40.0805 140.218 43.8296 140.707 48.3937C141.228 52.9579 131.811 55.0769 136.438 64.3356C140.316 72.0621 157.554 67.5305 157.554 67.5305L171.598 56.1528C171.598 56.1528 179.745 55.9572 176.421 46.9593C174.987 43.0472 179.908 41.0259 177.887 36.1684C175.411 30.2024 171.142 25.9316 171.142 25.9316L156.641 27.0727Z"
                            //                 fill="#7D5034"/>
                            //             <path
                            //                 d="M171.175 50.5132C171.175 50.5132 168.242 35.1582 162.768 36.3644C157.293 37.538 158.564 45.8187 152.014 48.0682C145.758 50.2198 141.717 55.436 146.605 61.6302C149.733 65.5749 164.462 60.2935 165.049 60.2935C165.635 60.2609 171.175 50.5132 171.175 50.5132Z"
                            //                 fill="#40291B"/>
                            //             <path
                            //                 d="M187.533 77.8977L186.62 78.4519C184.991 79.4952 182.938 79.0387 182.091 77.5065L170.947 57.2939C170.099 55.729 170.718 53.61 172.348 52.5993L173.26 52.0451C174.889 51.0019 177.399 52.6971 178.246 54.2294L188.934 73.1706C189.814 74.7354 189.195 76.8545 187.533 77.8977Z"
                            //                 fill="#294366"/>
                            //             <path
                            //                 d="M149.831 89.4712C149.831 89.4712 143.998 98.6973 146.279 107.923C148.56 117.149 148.56 117.149 148.56 117.149L161.041 116.074L163.028 92.8617L149.831 89.4712Z"
                            //                 fill="#385680"/>
                            //             <path
                            //                 d="M176.583 144.762H148.82C146.083 144.762 143.9 142.448 144.095 139.709L146.278 107.956L157.781 86.7979H176.453L176.583 144.762Z"
                            //                 fill="#385680"/>
                            //             <path
                            //                 d="M165.374 47.3508C161.268 47.09 158.009 43.5039 158.14 39.3635L158.433 30.4961C158.563 26.3557 162.05 23.1608 166.156 23.4216C170.262 23.6824 173.52 27.2686 173.39 31.4089L173.097 40.2764C172.966 44.4167 169.48 47.6116 165.374 47.3508Z"
                            //                 fill="#D48460"/>
                            //             <path
                            //                 d="M164.853 62.8034C158.336 63.2924 148.788 55.4356 146.214 53.6751L157.488 49.5348C158.824 47.8721 159.216 49.5674 159.541 49.6652C159.835 49.5348 161.138 49.5022 162.148 49.7956C164.886 50.5454 168.991 50.904 172.054 51.7516C174.27 52.5667 174.466 55.403 174.401 55.8594C174.01 59.1847 169.708 62.4448 164.853 62.8034Z"
                            //                 fill="#EBB85B"/>
                            //             <path
                            //                 d="M160.29 52.9578H160.844C162.571 52.9904 164.494 52.2732 164.527 50.9692L164.299 41.5149C164.331 40.2435 162.93 39.1676 161.17 39.135H160.616C158.889 39.1024 157.423 40.1457 157.39 41.4171L156.608 51.0344C156.576 52.371 158.531 52.9252 160.29 52.9578Z"
                            //                 fill="#D48460"/>
                            //             <path
                            //                 d="M154.621 39.1027C155.208 40.7979 156.544 41.776 157.554 41.287C158.564 40.7653 158.922 38.9723 158.336 37.277C157.749 35.5818 156.413 34.6037 155.403 35.0928C154.36 35.6144 154.035 37.4074 154.621 39.1027Z"
                            //                 fill="#D48460"/>
                            //             <path
                            //                 d="M166.58 26.4203C166.58 26.4203 173.505 28.9631 173.375 33.5925C173.375 33.5925 177.073 22.8015 167.069 22.1821L166.58 26.4203Z"
                            //                 fill="#7D5034"/>
                            //             <path
                            //                 d="M166.626 26.4709C166.626 26.4709 159.169 30.6743 159.28 36.7188L153.75 37.179C153.75 37.179 151.008 20.0961 167.068 22.1825L166.626 26.4709Z"
                            //                 fill="#7D5034"/>
                            //             <path
                            //                 d="M171.468 87.8085L156.348 87.3847C153.089 87.5151 146.409 86.5045 146.409 83.7986L146.246 60.0977C146.214 57.3918 148.853 55.1097 152.144 54.9793L171.24 54.2947C174.498 54.1643 173.977 56.5441 174.01 59.25L176.356 82.8858C176.388 85.6243 174.759 87.6781 171.468 87.8085Z"
                            //                 fill="#EBB85B"/>
                            //             <path
                            //                 d="M174.596 97.7843C176.128 96.8715 177.008 95.111 176.845 93.2528L175.541 77.8325C180.396 71.7687 178.995 66.357 175.834 58.2393L175.769 55.2726C175.9 53.5122 174.792 51.9473 173.162 51.5887L169.708 50.8389L168.079 99.7078L174.596 97.7843Z"
                            //                 fill="#294366"/>
                            //             <path
                            //                 d="M192.128 193.338H128.52C127.998 193.338 127.575 192.914 127.575 192.393C127.575 191.871 127.998 191.447 128.52 191.447H192.128C192.649 191.447 193.073 191.871 193.073 192.393C193.073 192.914 192.649 193.338 192.128 193.338Z"
                            //                 fill="#D8D6DF"/>
                            //             <path
                            //                 d="M164.201 49.5023C164.006 50.9041 163.517 51.9473 162.344 52.5342L164.755 56.5767C166.482 55.5334 167.102 53.6426 166.971 50.4803C166.939 49.8609 166.515 49.3067 165.896 49.1436L165.081 48.9154C164.658 48.8176 164.234 49.111 164.201 49.5023Z"
                            //                 fill="white"/>
                            //             <path
                            //                 d="M160.616 57.0656L162.8 52.6971C159.769 51.8494 158.14 51.23 157.325 49.6978C156.901 48.9153 155.989 48.5567 155.174 48.9153C154.262 49.3065 153.838 50.3824 154.327 51.2952C155.337 53.2187 157.521 55.0117 160.616 57.0656Z"
                            //                 fill="white"/>
                            //             <path
                            //                 d="M149.114 89.3401C150.971 90.7094 148.593 82.5591 150.32 79.9184L149.961 63.7483C151.688 61.1402 151.265 57.3259 149.538 54.9134L149.212 54.3592C147.354 52.99 143.509 54.131 141.782 56.7391L137.741 84.3848C137.741 94.3281 149.114 89.3401 149.114 89.3401Z"
                            //                 fill="#294366"/>
                            //             <path
                            //                 d="M168.763 50.0888C168.763 50.0888 167.101 49.1434 167.231 50.7408C169.415 58.3369 169.578 67.6934 168.665 77.9953L173.911 67.726L171.598 62.3142L174.498 51.7841L168.763 50.0888Z"
                            //                 fill="#385680"/>
                            //             <path
                            //                 d="M154.327 50.0894C154.327 50.0894 159.15 64.0752 159.28 74.0511C159.411 84.027 159.346 99.4473 159.346 99.4473C159.346 99.4473 146.702 98.9583 145.757 94.0029C144.845 89.0802 146.246 53.6755 146.246 53.6755L154.327 50.0894Z"
                            //                 fill="#294366"/>
                            //             <path
                            //                 d="M168.6 78.1263L164.331 79.2673C162.897 79.5607 162.963 80.6692 163.256 82.2992C163.549 83.9293 163.712 84.7443 165.146 84.4509L169.708 84.0597C171.142 83.7663 172.087 82.2014 171.761 80.5714C171.468 78.9087 170.034 77.8329 168.6 78.1263Z"
                            //                 fill="#D48460"/>
                            //             <path
                            //                 d="M162.376 79.2671L145.659 80.4408C144.095 80.5712 141.651 81.7448 141.749 83.5053C141.749 83.5053 140.902 88.7866 146.409 88.3628L163.126 84.6789C164.69 84.5485 165.407 83.5705 165.309 81.81C165.244 80.017 163.94 79.1041 162.376 79.2671Z"
                            //                 fill="#D48460"/>
                            //             <path
                            //                 d="M153.187 50.1873C153.187 50.1873 154.686 49.307 154.914 50.9045C157.423 58.7939 159.509 67.3028 159.313 78.8435L152.861 67.8896L155.11 61.891L148.821 52.4367L153.187 50.1873Z"
                            //                 fill="#385680"/>
                            //             <path
                            //                 d="M170.816 90.84H183.231C186.066 90.84 189.129 88.558 190.009 85.7543L196.983 63.8138C197.862 61.0101 196.298 58.728 193.431 58.728H181.015C178.18 58.728 175.117 61.0101 174.238 63.8138L167.264 85.7543C166.384 88.558 167.981 90.84 170.816 90.84Z"
                            //                 fill="#DDDDDD"/>
                            //             <path
                            //                 d="M196.136 72.4211L195.647 71.4756C194.832 69.9434 192.714 70.8236 191.899 73.1383C191.867 73.2361 191.834 73.3339 191.802 73.4317C191.215 75.3226 191.736 77.0504 192.975 77.2786L194.311 77.3764C195.582 77.1156 197.341 72.6493 196.136 72.4211Z"
                            //                 fill="#D48460"/>
                            //         </g>
                            //         <defs>
                            //             <clipPath id="clip0_8957_9381">
                            //                 <rect width="200" height="192" fill="white"/>
                            //             </clipPath>
                            //         </defs>
                            //     </svg>
                            // </>
                            :

                            // <p>
                            //     Invite Remodeling Pros and Vendors in your area. Here is a list of Pros and Vendor types you should have
                            //     as part of your network: Architects, Interior Designers, Structural Engineers, MEP
                            //     Engineers, General Contractors, HVAC Contractors, Electrical Contractors, Plumbing
                            //     Contractors, Door/Window Installers and/or Vendors, Drywall Installers, Painters,
                            //     Lighting Companies, Kitchen Companies, Closet Companies, Flooring Companies, Roofers,
                            //     etc.
                            // </p>
                            <>
                                <video controls width='100%' height='auto'>
                                    <source
                                        src={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Surveyor+-+Invite+Pros+and+Vendors.mp4'}
                                        type='video/mp4'/>
                                    <p>Your browser does not support the video element.</p>
                                </video>
                            </>
                        }
                    </div>
                    <div className="form-container" id="invite-network">
                        {showAlertMessage && (
                            <AlertMessage
                                messageHeader={"No email or invalid address entered."}
                                messageType={"error"}
                                onButtonClick={closeDuplicatedMsg}
                            />
                        )}
                        {showNoContacts ?
                            <AlertMessage
                                messageHeader={"No email or invalid address entered."}
                                messageType={"error"}
                                onButtonClick={closeDuplicatedMsg}
                            />
                            : ''
                        }
                        {showAlertMessageAddEmail && (
                            <AlertMessage
                                messageHeader={"No email or invalid address entered."}
                                messageBody={""}
                                messageType={"error"}
                                onButtonClick={closeDuplicatedMsg}
                            />
                        )}
                        <form action="">
                            <section className="emails">
                                {myContacts.map((email, index) => (
                                    <div className="form-field text-input" key={index}>
                                        <label htmlFor={`invite-email-address-${index}`}>E-mail Address</label>
                                        <input
                                            type="email"
                                            id={`invite-email-address-${index}`}
                                            value={email}
                                            onChange={(e) => {
                                                handleEmailChange(index, e.target.value);
                                                setShowWrongEmails(false);
                                                setShowAlertMessageAddEmail(false);
                                                setShowAlertMessage(false);
                                                setShowNoContacts(false);
                                                setShowSuccessMessage(false);
                                                setShowNotAllowed(false);
                                            }}
                                            onBlur={(e) => {
                                                checkForDuplicates(e.target.value, index)
                                            }} // Check for duplicates when the user leaves the input field
                                        />
                                    </div>
                                ))}
                                {showDuplicatedEmailsMsg ?
                                    <AlertMessage
                                        messageHeader={"Duplicate emails are not allowed."}
                                        messageBody={""}
                                        messageType={"error"}
                                        onButtonClick={closeDuplicatedMsg}
                                    />
                                    : ''
                                }
                                <div className="button-group">
                                    <button type="button" className="button-s button-tertiary" id="button-add-email"
                                            onClick={addEmailField}>
                                        <span className="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5V19M5 12H19" stroke="currentColor" strokeWidth="2"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                        <span className="text">Add Another Email</span>
                                    </button>
                                </div>
                            </section>
                        </form>
                        <div className="button-group">
                            <button type="button" className="button-m button-primary"
                                    onClick={() => shareContacts(type)} disabled={disableButton}>
                                <span className="text">{buttonTitle}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
