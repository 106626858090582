import React, {useEffect, useState} from 'react';
import {formatDate} from '../../utils/dateFormat';
import {useNavigate} from 'react-router-dom';
import Axios from 'axios';
import AlertMessage from '../libs/AlertMessage';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header'
import PageTitle from '../libs/PageTitle';
import Tabs from '../libs/Tabs';
import uuid from 'react-uuid';
import EmptyProMedia from "./EmptyProMedia";
import NoCoverImageFree from "../../images/no-cover-image-free.jpeg";
import VideoModal from "../libs/VideoModal";

export default function ProReplies() {
    const [showProReq, setShowProReq] = useState(false);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const [packagesSubmission, setPackagesSubmission] = useState([]);
    const [scopeOfWorkThumbnail, setScopeOfWorkVideoThumbnail] = useState('');
    const [videoAddress, setVideoAddress] = useState('');
    const [videoFileName, setVideoFileName] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNoReplies, setShowNoReplies] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [singleReply, setSingleReply] = useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const userId = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    sessionStorage.setItem('isPropertyOwner', 'false');
    const navigate = useNavigate();
    const handleGoToLogin = () => navigate('/login');

    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            getFreeLeadReplies(userId);
        }
    }, []);

    const goToProject = (pck) => {
        setSingleReply(pck);
        const videoNameArray = pck.videoName.split('/');
        const videoName = videoNameArray[videoNameArray.length - 1];
        if (pck.isFreeLead === 0) {
            setVideoFileName(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX}/${pck.packageName}/${process.env.REACT_APP_PRO_VIDEOS_GALLERY_PREFIX}/${videoName}`);
            setScopeOfWorkVideoThumbnail(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.packageName}/${process.env.REACT_APP_PRO_VIDEOS_GALLERY_PREFIX}/${videoName.replace('mp4', 'jpg')}`);
        } else {
            setVideoFileName(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX}/${pck.userId}/video-library/${videoName}`);
            setScopeOfWorkVideoThumbnail(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.userId}/video-library/${videoName.replace('mp4', 'jpg')}`);
        }
        setVideoAddress(`${pck.address}${pck.address2 ? ' ' + pck.address2 : ''}, ${pck.city}, ${pck.state} ${pck.zip}`);
        setShowVideoModal(true);
    }

    const getFreeLeadReplies = (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getLeadsReplies`, {
            userId: userId,
            isAdmin: false
        }).then((response) => {
            if (response.data.length === 0) {
                setShowNoReplies(true);
            } else {
                setShowNoReplies(false);
            }
            setPackagesSubmission(response.data);
        }).catch(function (error) {
            console.log('download package error = ', error);
        })
    }

    const deleteReply = () => {
        const leadId = singleReply.id;
        const isFreeLead = singleReply.isFreeLead;
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteVideoReply`, {
            id: leadId,
            isFreeLead: isFreeLead
        }).then((response) => {
            getFreeLeadReplies(userId);
            setShowDeleteModal(false);
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const handleShowVideoModal = () => {
        setShowModal(true);
    };

    const handleCloseVideoModal = () => {
        setShowModal(false);
    };

    return (
        <div id="fazzad-site-container">
            <Header/>
            <main id="fazzad-main">
                <div id="app-container">
                    <Tabs
                        userType={userType}
                        setShowProReq={setShowProReq}
                        currentTab={'replies'}
                    />

                    {showNoReplies ? (
                            // <AlertMessage
                            //     messageHeader={"You have not replied to any leads."}
                            //     messageBody={""}
                            //     startDate={null}
                            //     setShowWarningContainerMessage={''}
                            //     messageType={"informational"}
                            //     showButton={false}
                            // />
                            <EmptyProMedia
                                proMediaType={'replies'}
                                title={'Your Replies'}
                                description={'In the \'Replies\' section, you can track all the projects you\'ve responded to. You\'ll also be able to see the status of each submission, including whether the Property Owner has liked or deleted your video.'}
                                typeName={'Replies'}
                            />
                        ) :
                        <div className="fz-fd-row fz-jc-space-between">
                            <div id="pro-title" className="nowrap">
                                <PageTitle
                                    headerText={'Replies'}
                                    subHeaderText={'Below are the projects you have replied to.'}
                                    showSubHeader={packagesSubmission.length > 0 ? true : false}
                                />
                            </div>

                            <a className="fz-info" onClick={() => {
                                // setShowModal(true)
                                handleShowVideoModal()
                            }}>
                                <span className="icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        </svg>
                                </span>
                                <span className="text">Info</span>
                            </a>
                        </div>
                    }
                    <div className="replies-container">
                        {
                            packagesSubmission.map((pck, index) => {
                                let videoName = '';
                                let imgSrc = '';
                                const videoNameArray = pck.videoName.split('/');
                                videoName = videoNameArray[videoNameArray.length - 1];
                                if (pck.isFreeLead === 0) {
                                    imgSrc = `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.packageName}/${process.env.REACT_APP_PRO_VIDEOS_GALLERY_PREFIX}/${videoName.replace('mp4', 'jpg')}`;
                                } else {
                                    imgSrc = `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.userId}/video-library/${videoName.replace('mp4', 'jpg')}`;
                                }
                                return (
                                    <div className="reply" key={`replay_${index}`}>
                                        <div className="image" key={`div_image_${index}`}>
                                            <img
                                                src={imgSrc}
                                                alt=""
                                                onError={(e) => e.target.src = NoCoverImageFree}
                                                key={`image_${index}`}
                                                onClick={() => {
                                                    goToProject(pck)
                                                }}
                                            />
                                        </div>
                                        <div className="content" key={`content${index}`}>
                                            <div className="inner" key={`inner${index}`}>
                                                <h3 className="h5"
                                                    key={`h3${index}`}>
                                                    <a href="/" onClick={(e) => {
                                                        e.preventDefault();
                                                        goToProject(pck)
                                                    }} key={`ahref_${index}`}>
                                                        {`${pck.address}${pck.address2.length > 0 ? ' ' + pck.address2 : ''}, ${pck.city}, ${pck.state} ${pck.zip}`}
                                                    </a>
                                                </h3>
                                                <p className="submission-date" key={`submitted${index}`}>Submitted
                                                    on {formatDate(pck.dateSubmitted)}</p>
                                                {pck.homeOwnerLiked ?
                                                    <p className="action-taken" key={`action${index}`}>
                                                        {getSvg('liked')}
                                                        <span className="text" key={`span${index}`}>Liked by property owner</span>
                                                    </p>
                                                    : ''
                                                }
                                                {pck.hasVideoBeenWatched && pck.homeOwnerLiked === 0 ?
                                                    <p className="action-taken" key={`actiond_${index}`}>
                                                        {getSvg('watching')}
                                                        <span className="text" key={`span_ds${index}`}>Watched by property owner</span>
                                                    </p>
                                                    : ''
                                                }
                                                {pck.homeOwnerDeleted ?
                                                    <p className="action-taken" key={`actionds_${index}`}>
                                                        {getSvg('delete')}
                                                        <span className="text" key={`span_d${index}`}>Deleted by property owner</span>
                                                    </p>
                                                    : ''
                                                }
                                            </div>
                                            <div className="actions" key={`actionsbuttton_${index}`}>
                                                <div className="button-group" key={`buttongroup${index}`}>
                                                    <button
                                                        type="button"
                                                        className="button-s button-destructive-outlined"
                                                        key={`button_action${index}`}
                                                        onClick={(e) => {
                                                            setSingleReply(pck);
                                                            setShowDeleteModal(true)
                                                        }}
                                                    >
                                                        {getSvg('delete', `image_delete_${index}`)}
                                                        <span className="text"
                                                              key={`deletereply${index}`}>Delete Reply</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {showVideoModal ?
                            <div className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <h2 className="modal-title h5">{videoAddress}</h2>
                                        <button type="button"
                                                className="button-tertiary button-s button-icon modal-close"
                                                onClick={() => setShowVideoModal(false)}>
                                        <span className="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                        </button>
                                    </div>
                                    <div className="modal__content">
                                        <video
                                            className='fz-video'
                                            width='100%'
                                            height='auto'
                                            controls
                                            poster={scopeOfWorkThumbnail}
                                            key={uuid()}
                                        >
                                            <source
                                                src={videoFileName}
                                                type='video/mp4'
                                            />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            : ''
                        }
                    </div>

                    {showProReq ?
                        <div id='modal-success' className='fz-overlay'>
                            <div className='fz-overlay-container'>
                                <div className='modal-content'>
                                    <button id='close-package' className='button button-blank icon-cancel'
                                            value='Close page' onClick={() => {
                                        setShowProReq(false)
                                    }}></button>
                                    <article className='fz-container fz-delisted'>
                                        <div className='block'>
                                            <div className="fz-alert alert-info">
                                                <i className="icon-info">Info:</i>
                                                <p>This feature is for Premium customers only.<br/><br/></p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                    {showDeleteModal ?
                        <div className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                </div>
                                <div className="modal__action">
                                    <div className="button-group">
                                        <button className="button button-m button-secondary" type="button"
                                                onClick={() => {
                                                    setShowDeleteModal(false)
                                                }}>No, Cancel
                                        </button>
                                        <button className="button button-m button-destructive" type="button"
                                                onClick={() => {
                                                    deleteReply()
                                                }}>Yes, Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
            </main>
            {showModal && (

                <VideoModal videoUrl={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Pro+Vendor+-+Replies.mp4'} onClose={handleCloseVideoModal} />

                // <div className="modal-container">
                //     <div className="modal modal-lg">
                //         <div className="modal__header">
                //             <p></p>
                //             <button type="button" className="button-tertiary button-s button-icon modal-close"
                //                     onClick={() => {
                //                         setShowModal(false)
                //                     }}>
                //                 {getSvg('close')}
                //             </button>
                //         </div>
                //         <div className='modal-content'>
                //             <div className="fz-jc-center fz-block">
                //                 <svg width="198" height="192" viewBox="0 0 198 192" fill="none"
                //                      xmlns="http://www.w3.org/2000/svg">
                //                     <path
                //                         d="M116.487 130.355H3.19984C1.43345 130.353 0.00200185 128.921 0 127.155V95.1632C0.00200185 93.3968 1.43345 91.9654 3.19984 91.9634H116.487C118.254 91.9654 119.685 93.3968 119.687 95.1632V127.155C119.685 128.921 118.254 130.353 116.487 130.355ZM3.19984 92.9796C1.99442 92.981 1.01761 93.9579 1.01617 95.1632V127.155C1.01761 128.36 1.99442 129.337 3.19984 129.339H116.487C117.693 129.337 118.67 128.36 118.671 127.155V95.1632C118.67 93.9578 117.693 92.981 116.487 92.9795L3.19984 92.9796Z"
                //                         fill="#CCCCCC"/>
                //                     <path
                //                         d="M156.477 185.857L151.872 185.856L149.681 168.093L156.478 168.093L156.477 185.857Z"
                //                         fill="#D48460"/>
                //                     <path
                //                         d="M156.639 190.661L142.477 190.661V190.482C142.478 187.437 144.945 184.97 147.99 184.969L150.577 183.007L155.403 184.97L156.64 184.97L156.639 190.661Z"
                //                         fill="#2F2E41"/>
                //                     <path
                //                         d="M191.567 161.617L189.387 165.673L172.703 159.191L175.922 153.205L191.567 161.617Z"
                //                         fill="#D48460"/>
                //                     <path
                //                         d="M195.876 163.749L189.169 176.222L189.011 176.138C186.33 174.696 185.325 171.354 186.767 168.672L186.263 165.464L190.277 162.143L190.863 161.054L195.876 163.749Z"
                //                         fill="#2F2E41"/>
                //                     <path
                //                         d="M147.77 104.508L143.706 137.026L145.738 147.928L182.32 163.954L184.352 157.349C175.001 151.627 165.005 142.968 158.707 142.331C158.707 142.331 172.412 110.859 171.904 108.319C171.396 105.779 147.77 104.508 147.77 104.508Z"
                //                         fill="#2F2E41"/>
                //                     <path
                //                         d="M147.07 136.932L144.944 150.664L150.311 178.689H157.424C159.979 169.272 160.111 156.896 157.678 146.425L147.07 136.932Z"
                //                         fill="#2F2E41"/>
                //                     <path
                //                         d="M151.64 64.7786L154.919 59.0647L158.44 56.2403L165.045 55.2241L166.88 56.6352C173.484 66.7286 175.947 78.9779 173.757 90.8394L171.954 100.606L173.505 107.975C173.993 110.292 172.511 112.566 170.193 113.054C169.909 113.114 169.619 113.144 169.328 113.146L152.939 113.213C148.85 113.23 145.522 109.929 145.505 105.839C145.503 105.458 145.531 105.077 145.588 104.699L151.64 64.7786Z"
                //                         fill="#E6E6E6"/>
                //                     <path
                //                         d="M164.504 84.4543L157.955 83.5161C155.74 87.2202 154.952 97.0792 154.952 97.0792L148.542 112.314C147.208 113.221 146.861 115.038 147.767 116.372C148.674 117.707 150.49 118.054 151.825 117.148C152.691 116.559 153.176 115.552 153.096 114.509L163.621 96.7753L164.504 84.4543Z"
                //                         fill="#D48460"/>
                //                     <path
                //                         d="M163.462 66.2709C167.272 66.8609 169.882 70.4279 169.292 74.238C169.226 74.6649 169.12 75.0848 168.976 75.4922L167.587 79.4318C167.587 79.4318 166.83 91.2939 163.945 94.6423L155.09 93.8408C155.09 93.8408 156.709 87.8562 155.466 84.9606C154.705 83.1873 154.748 77.0626 155.498 72.0802C156.088 68.2802 159.647 65.6782 163.447 66.2686L163.462 66.2709Z"
                //                         fill="#E6E6E6"/>
                //                     <path
                //                         d="M150.779 73.0504L149.223 66.6192C144.962 65.9341 135.513 68.8559 135.513 68.8559L118.987 68.5471C117.651 67.6433 115.835 67.9941 114.931 69.3305C114.027 70.667 114.378 72.483 115.715 73.3868C116.582 73.9732 117.697 74.0507 118.637 73.5899L139.007 76.7948L150.779 73.0504Z"
                //                         fill="#D48460"/>
                //                     <path
                //                         d="M167.282 65.3449C168.146 69.1024 165.8 72.8485 162.042 73.7122C161.621 73.8089 161.192 73.8664 160.76 73.8839L156.586 74.0526C156.586 74.0526 145.288 77.7449 141.109 76.3055L138.573 67.7846C138.573 67.7846 144.731 67.0708 146.96 64.8436C148.325 63.4796 154.03 61.2496 158.935 60.1001C162.684 59.2404 166.419 61.5822 167.279 65.3304L167.282 65.3449Z"
                //                         fill="#E6E6E6"/>
                //                     <path
                //                         d="M168.585 46.3389C169.353 41.6042 166.138 37.1432 161.403 36.3748C156.668 35.6065 152.207 38.8219 151.439 43.5565C150.671 48.2912 153.886 52.7522 158.621 53.5206C163.355 54.2889 167.816 51.0736 168.585 46.3389Z"
                //                         fill="#D48460"/>
                //                     <path
                //                         d="M160.159 53.3856C161.43 53.4055 162.7 53.3167 163.956 53.1203C165.2 52.8672 167.985 50.8268 168.761 49.599C169.326 48.5457 169.716 47.4069 169.914 46.2279C170.48 43.4598 169.846 40.5815 168.169 38.3076C167.85 37.8373 167.411 37.4619 166.896 37.221C166.85 37.2032 166.804 37.1874 166.756 37.1739C166.006 36.9766 165.333 36.5597 164.822 35.9768C164.722 35.8589 164.615 35.7483 164.5 35.6458C163.922 35.1583 163.237 34.8144 162.501 34.6423C161.313 34.3243 159.594 34.6399 157.391 35.5813C156.344 35.9489 155.21 35.9838 154.143 35.6811C154.036 35.6606 153.927 35.698 153.856 35.7794C153.46 36.1513 152.938 36.3595 152.394 36.3621C152.061 36.3767 151.712 36.8375 151.29 37.4384C151.194 37.5748 151.083 37.734 151.002 37.8308L150.991 37.6448L150.803 37.8523C149.834 38.9199 149.915 40.5704 150.982 41.5389C151.269 41.7992 151.61 41.9923 151.981 42.1043C152.316 42.1934 152.659 42.2481 153.005 42.2675C153.214 42.2863 153.431 42.3058 153.639 42.3419C154.995 42.6195 156.105 43.5891 156.563 44.8952C156.755 45.4302 157.344 45.7084 157.879 45.5166C157.963 45.4864 158.042 45.4455 158.116 45.3948C158.555 45.0529 159.134 44.9475 159.665 45.1127C159.891 45.2386 160.067 45.4383 160.164 45.6786C160.281 45.9463 160.475 46.173 160.722 46.3298C161.565 46.7646 161.624 48.691 161.321 50.2969C161.029 51.8449 160.452 52.9647 159.917 53.0201C159.506 53.0626 159.459 53.0919 159.429 53.1646L159.402 53.2297L159.448 53.2907C159.676 53.3731 159.918 53.4055 160.159 53.3856Z"
                //                         fill="#2F2E41"/>
                //                     <path
                //                         d="M116.487 82.5256H3.19986C1.71392 82.5239 0.509743 81.3197 0.508057 79.8338V47.8421C0.509764 46.3562 1.71395 45.152 3.19986 45.1504H116.487C117.973 45.1521 119.177 46.3562 119.179 47.8421V79.8338C119.177 81.3197 117.973 82.5239 116.487 82.5256Z"
                //                         fill="white"/>
                //                     <path
                //                         d="M116.487 37.8835H3.19986C1.71392 37.8818 0.509743 36.6776 0.508057 35.1917V3.2C0.509764 1.71409 1.71395 0.509947 3.19986 0.508301H116.487C117.973 0.509962 119.177 1.71409 119.179 3.2V35.1917C119.177 36.6776 117.973 37.8818 116.487 37.8835Z"
                //                         fill="white"/>
                //                     <path
                //                         d="M116.487 83.0335H3.19984C1.43345 83.0315 0.00200185 81.6 0 79.8336V47.8419C0.00200185 46.0755 1.43345 44.6441 3.19984 44.6421H116.487C118.254 44.6441 119.685 46.0755 119.687 47.8419V79.8336C119.685 81.6 118.254 83.0315 116.487 83.0335ZM3.19984 45.6583C1.99442 45.6597 1.01761 46.6365 1.01617 47.842V79.8336C1.01761 81.0391 1.99442 82.0159 3.19984 82.0173H116.487C117.693 82.0159 118.67 81.0391 118.671 79.8336V47.8419C118.67 46.6365 117.693 45.6597 116.487 45.6583L3.19984 45.6583Z"
                //                         fill="#CCCCCC"/>
                //                     <path
                //                         d="M116.487 38.3914H3.19984C1.43345 38.3894 0.00200185 36.9579 0 35.1915V3.19983C0.00200185 1.43345 1.43345 0.00200185 3.19984 0H116.487C118.254 0.00200185 119.685 1.43345 119.687 3.19983V35.1915C119.685 36.9579 118.254 38.3894 116.487 38.3914ZM3.19984 1.01619C1.99442 1.01763 1.01761 1.99445 1.01617 3.19986V35.1915C1.01761 36.397 1.99442 37.3738 3.19984 37.3752H116.487C117.693 37.3738 118.67 36.397 118.671 35.1915V3.19983C118.67 1.99442 117.693 1.01761 116.487 1.01617L3.19984 1.01619Z"
                //                         fill="#CCCCCC"/>
                //                     <path
                //                         d="M109.23 25.3597H10.457C9.73866 25.3607 9.15552 24.7791 9.15454 24.0608C9.15355 23.3424 9.7351 22.7593 10.4535 22.7583H109.23C109.948 22.7573 110.531 23.3389 110.532 24.0572C110.533 24.7756 109.952 25.3587 109.233 25.3597H109.23Z"
                //                         fill="#3D3D3D"/>
                //                     <path
                //                         d="M109.23 71.0872H10.457C9.73866 71.0882 9.15552 70.5067 9.15454 69.7883C9.15355 69.07 9.7351 68.4868 10.4535 68.4858H109.23C109.948 68.4849 110.531 69.0664 110.532 69.7848C110.533 70.5031 109.952 71.0862 109.233 71.0872H109.23Z"
                //                         fill="#3D3D3D"/>
                //                     <path
                //                         d="M98.6076 30.4995H10.457C9.73862 30.4995 9.15625 29.9171 9.15625 29.1987C9.15625 28.4803 9.73862 27.8979 10.457 27.8979H98.6076C99.326 27.8979 99.9084 28.4803 99.9084 29.1987C99.9084 29.9171 99.326 30.4995 98.6076 30.4995Z"
                //                         fill="#3D3D3D"/>
                //                     <path
                //                         d="M98.6076 76.227H10.457C9.73862 76.227 9.15625 75.6447 9.15625 74.9263C9.15625 74.2079 9.73862 73.6255 10.457 73.6255H98.6076C99.326 73.6255 99.9084 74.2079 99.9084 74.9263C99.9084 75.6447 99.326 76.227 98.6076 76.227Z"
                //                         fill="#3D3D3D"/>
                //                     <path
                //                         d="M116.487 129.846H3.19986C1.71392 129.845 0.509759 128.64 0.508057 127.155V95.1629C0.509764 93.677 1.71395 92.4728 3.19986 92.4712H116.487C117.973 92.4728 119.177 93.677 119.179 95.1629V127.155C119.177 128.64 117.973 129.845 116.487 129.846Z"
                //                         fill="white"/>
                //                     <path
                //                         d="M198 191.5C198.001 191.775 197.792 191.999 197.533 192H20.47C20.2104 192 20 191.776 20 191.5C20 191.224 20.2104 191 20.47 191H197.53C197.789 190.999 197.999 191.222 198 191.497V191.5Z"
                //                         fill="#CCCCCC"/>
                //                     <rect x="8.04761" y="5.58887" width="106.19" height="14.7344" rx="7.36722"
                //                           fill="#D9D9D9"/>
                //                     <rect x="8.04761" y="51.3164" width="106.19" height="14.7344" rx="7.36722"
                //                           fill="#D9D9D9"/>
                //                     <rect x="8.04761" y="98.5684" width="106.19" height="14.7344" rx="7.36722"
                //                           fill="#D9D9D9"/>
                //                     <path
                //                         d="M109.23 118.339H10.457C9.73866 118.34 9.15552 117.758 9.15454 117.04C9.15355 116.321 9.7351 115.738 10.4535 115.737H109.23C109.948 115.736 110.531 116.318 110.532 117.036C110.533 117.755 109.952 118.338 109.233 118.339H109.23Z"
                //                         fill="#3D3D3D"/>
                //                     <path
                //                         d="M98.6076 123.478H10.457C9.73862 123.478 9.15625 122.896 9.15625 122.178C9.15625 121.459 9.73862 120.877 10.457 120.877H98.6076C99.326 120.877 99.9084 121.459 99.9084 122.178C99.9084 122.896 99.326 123.478 98.6076 123.478Z"
                //                         fill="#3D3D3D"/>
                //                 </svg>
                //             </div>
                //             <h2 className="modal-title h4">Your Replies</h2>
                //             <p>In the 'Replies' section, you can track all the projects you've responded to. You'll also
                //                 be able to see the status of each submission, including whether the Property Owner has
                //                 liked or deleted your video.</p>
                //         </div>
                //         <div className="modal__action">
                //             <div className="button-group">
                //                 <button className="button button-m button-primary modal-close" type="button"
                //                         onClick={() => setShowModal(false)}>Close
                //                 </button>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            )}
        </div>
    )
}
