import React, {useEffect, useState, useRef} from 'react';
import {cleanMediaUrl} from '../../utils/clearMediaUrl';
import {S3} from '@aws-sdk/client-s3';
import {useNavigate, useParams} from 'react-router-dom';
import {Upload} from '@aws-sdk/lib-storage';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header'
import Tabs from '../libs/Tabs';
import PageTitle from "../libs/PageTitle";
import uuid from 'react-uuid';
import VideoComponent from '../libs/VideoComponent';
import * as formValidator from "../../utils/formValidation";
import {uploadWithSignedUrl} from "../../utils/awsHelpers";
import EmptyProMedia from "./EmptyProMedia";
import VideoModal from "../libs/VideoModal";

window.Buffer = window.Buffer || require("buffer").Buffer;
export default function ProMedia() {
    const videoRef = useRef(null);
    const {type} = useParams();
    const proTestimonialProfileBucketName = 'fazzad-pros-portfolio-testimonial-videos-source';
    const [fileToBeUploaded, setFileToBeUploaded] = useState('');
    const [fileProgressSelfVideo, setFileProgressSelfVideo] = useState(5);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [isTooLongVideo, setIsTooLongVideo] = useState(false);
    const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
    const [videoName, setVideoName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [showVideos, setShowVideos] = useState(true);
    const [singleVideo, setSingleVideo] = useState([]);
    const [showProcessingVideo, setShowProcessingVideo] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showNoVideos, setShowNoVideos] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [showFormErrorMessage, setShowFormErrorMessage] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [videosResponses, setVideosResponses] = useState([]);
    const [hasVideoName, setHasVideoName] = useState(true);
    const [hasProjectDescription, setHasProjectDescription] = useState(true);
    const [showPortfolioModal, setShowPortfolioModal] = React.useState(false);
    const [showTestimonialModal, setShowTestimonialModal] = React.useState(false);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const processingMessage = process.env.REACT_APP_PROCESSING_MESSAGE;
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    const handleGoToLogin = () => navigate('/login');
    const navigate = useNavigate();
    let tempVideoCounter = 0;
    sessionStorage.setItem('isPropertyOwner', 'false');
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_BUCKET_PROS_VIDEOS_PREFIX;
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const messageWaiting = process.env.REACT_APP_PROCESSING_MESSAGE_WAIT;
    const userId = sessionStorage.getItem('userId');
    const uploadType = type;


    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            getProsVideos(userId, type);
        }
    }, []);

    const handleTabClick = (currentTab) => {
        getProsVideos(userId, currentTab);
    }

    const checkProjectDescription = (projectDescription) => {
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
        } else {
            setHasProjectDescription(true);
        }
    }

    const checkVideoName = (videoName) => {
        if (videoName.trim().length === 0) {
            setHasVideoName(false);
        } else {
            setHasVideoName(true);
        }
    }

    const getProsVideos = (userId, type) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, {
            userId: userId,
            videoType: type
        }).then((response) => {
            setVideosResponses(filterData(type, response.data));
            if (response.data.length === 0) {
                setShowNoVideos(true);
            } else {
                setShowNoVideos(false);
            }
        }).catch(function (error) {
            console.log('error getting pros videos = ', error);
        })
    }
    const filterData = (type, originalData) => {
        const filteredData = originalData.filter((item) => item.videoType === type);
        return filteredData;
    }
    const sendUploadError = (err) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendGenericErrorEmail`, {
            errorSubject: `Error Uploading ${type} video`,
            errorMessage: 'Check the cors issues'
        }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('error sending generic error email = ', error);
        })
    }

    const checkUploadForm = () => {
        let isValid = true;
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }
        if (videoName.trim().length === 0) {
            setHasVideoName(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }
        return isValid;
    }

    const saveVideoUploaded = (userId, uploadType, uploadFileName, videoName, projectDescription, referenceNumber) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveProVideos`, {
            userId: userId,
            videoType: uploadType,
            uploadFileName: uploadFileName,
            projectName: videoName,
            projectDescription: projectDescription,
            referenceNumber: referenceNumber
        }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const changeHandlerUploads = (event) => {
        event.preventDefault();
        setShowErrorMessage(false);

        const chosenFile = event.target.files[0];
        setFileToBeUploaded(chosenFile.name);

        const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
        if (!videoExtensionsRegex.test(chosenFile.name)) {
            setIsValidVideo(false);
            return;
        }

        const video = document.createElement('video');
        video.src = window.URL.createObjectURL(chosenFile);
        video.addEventListener('loadedmetadata', () => {
            setShowErrorMessage(false);

            const videoDurationInSeconds = video.duration;
            const videoDurationInMinutes = videoDurationInSeconds / 60;

            if (videoDurationInMinutes > 15) {
                setIsTooLongVideo(true);
                return;
            }

            setIsTooLongVideo(false);
            setSelectedUploads(event.target.files);
        });

        videoRef.current.blur();
    };

    const handleUpload = async () => {
        setShowFormErrorMessage(false);
        const uuids = uuid();
        tempVideoCounter = 0;
        let selfVideoArray = [];
        try {
            if (videoName.trim().length > 0 && projectDescription.trim().length > 0) {
                if (selectedUploads.length > 0) {
                    if (checkUploadForm()) {
                        setFileProgressSelfVideo(0);
                        setShowErrorMessage(false);
                        setShowProcessingVideo(true);
                        setShowProcessing(false);
                        setErrorUploadingVideo(false);
                        selfVideoArray = [...selectedUploads];
                        let videoArray = [];
                        videoArray = selfVideoArray.splice(0, 1);
                        try {
                            await Promise.all(
                                videoArray.map(async (file) => {
                                    const fileName = cleanMediaUrl(file.name);
                                    const key = `${uuids}/${fileName}`;
                                    await uploadWithSignedUrl(file, proTestimonialProfileBucketName, key, (myTotal) => {
                                            setFileProgressSelfVideo(myTotal);
                                        },
                                        () => {
                                            // setShowInfo(true);
                                            // setShowProcessing(false);
                                            // setShowVideos(true)
                                            // setShowUploadVideo(false);
                                            // getProsVideos(userId, type);
                                            // setVideoName('');
                                            // setProjectDescription('');
                                            // setSelectedUploads([]);
                                            // setFileToBeUploaded('');
                                        });

                                    setShowProcessingVideo(false);
                                    setShowInfo(false);
                                    setShowProcessing(true);
                                    setFileProgressSelfVideo(0);
                                    saveVideoUploaded(userId, uploadType, fileName, videoName, projectDescription, uuids);
                                    setTimeout(() => {
                                        setShowInfo(true);
                                        setShowProcessing(false);
                                        setShowVideos(true)
                                        setShowUploadVideo(false);
                                        getProsVideos(userId, type);
                                        setVideoName('');
                                        setProjectDescription('');
                                        setSelectedUploads([]);
                                        setFileToBeUploaded('');
                                    }, 2000);
                                })
                            );
                        } catch (e) {
                            setShowProcessingVideo(false);
                            setVideoName('');
                            setProjectDescription('');
                            setFileToBeUploaded('');
                            setSelectedUploads([]);
                            setErrorUploadingVideo(true);
                            sendUploadError(e);
                        }
                    }
                } else {
                    setShowErrorMessage(true);
                }
            } else {
                setShowFormErrorMessage(true);
            }
        } catch (e) {
            setShowInfo(true);
            setShowUploadVideo(false);
            setShowProcessing(false);
            console.error(`Uploading Videos file error: ${e}`);
        }
    };

    const deleteVideo = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteProUploadsVideos`, {singleVideo: singleVideo}).then((response) => {
            setShowDeleteModal(false);
            getProsVideos(userId, type);
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const handleAlertButtonClick = () => {
        setShowFormErrorMessage(false);
    };

    const handleShowTestimonialVideoModal = () => {
        setShowTestimonialModal(true);
    };

    const handleCloseTestimonialVideoModal = () => {
        setShowTestimonialModal(false);
    };

    const handleShowPortfolioVideoModal = () => {
        setShowPortfolioModal(true);
    };

    const handleClosePortfolioVideoModal = () => {
        setShowPortfolioModal(false);
    };

    return (
        <div id="fazzad-site-container">
            <Header/>
            <main id="fazzad-main">
                <div id="app-container">
                    {showVideos ?
                        <>
                            <Tabs
                                onTabClick={handleTabClick}
                                currentTab={type}
                            />
                            {showNoVideos ? (
                                    <>
                                        {uploadType === 'portfolio' && <EmptyProMedia
                                            proMediaType={'portfolio'}
                                            title={'Pro/Vendor Portfolio'}
                                            description={'You can showcase your work through videos of past projects. Property Owners will be able to view these video portfolios only after you\'ve reached out to them.'}
                                            typeName={'Portfolio'}
                                            onClick={() => {
                                                setShowUploadVideo(true)
                                            }}
                                        />
                                        }

                                        {uploadType === 'testimonial' && <EmptyProMedia
                                            proMediaType={'testimonial'}
                                            title={'Welcome to your Testimonials Page!'}
                                            description={'You can upload testimonial videos from past clients and projects. These videos highlight customer experiences and feedback, offering valuable insights into the quality of your services and the success of your work.'}
                                            typeName={'Testimonial'}
                                            onClick={() => {
                                                setShowUploadVideo(true)
                                            }}
                                        />
                                        }
                                    </>
                                )

                                // <AlertMessage
                                //     messageHeader={`You don't have any ${uploadType} videos uploaded.`}
                                //     messageBody={""}
                                //     startDate={null}
                                //     onButtonClick={handleAlertButtonClick}
                                //     messageType={"informational"}
                                //     showButton={false}
                                // />
                                :
                                <>
                                    <div className="fz-fd-row fz-jc-space-between">
                                        <PageTitle
                                            headerText={`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
                                        />

                                        <a className="fz-info" onClick={() => {
                                            if (uploadType === 'portfolio') {
                                                // setShowPortfolioModal(true);
                                                handleShowPortfolioVideoModal();
                                            } else {
                                                // setShowTestimonialModal(true);
                                                handleShowTestimonialVideoModal();
                                            }
                                        }}>
                                    <span className="icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                                stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            </svg>
                                    </span>
                                            <span className="text">Info</span>
                                        </a>
                                    </div>

                                    {/*<button type="button" className="fz-block-sm button-m button-primary"*/}
                                    {/*        onClick={(e) => {*/}
                                    {/*            setShowUploadVideo(true)*/}
                                    {/*        }}>*/}
                                    {/*    {getSvg('upload')}*/}
                                    {/*    <span className="text">Upload {type} video</span>*/}
                                    {/*</button>*/}
                                    {/*<p>Upload your {type} videos here.*/}
                                    {/*</p>*/}
                                </>
                            }
                            <div id="po-container">
                                <div id="po-feed-container" className="reel-view">
                                    {!showNoVideos &&
                                        <div className="button-group">
                                            <button type="button" className="button-m button-primary"
                                                    onClick={(e) => {
                                                        setShowUploadVideo(true)
                                                    }}>
                                                {getSvg('upload')}
                                                <span class="text">Upload {type} Video</span>
                                            </button>
                                        </div>
                                    }

                                    {videosResponses.map((pck, index) => {
                                        return (
                                            <div className="po-feed-item fz-drop rounded" key={`resp_${pck.id}`}>
                                                <VideoComponent
                                                    pck={pck}
                                                    videoKey={index}
                                                    videoType={'media'}
                                                    handleCopyToClipboard={null}
                                                    setShowDeleteModal={setShowDeleteModal}
                                                    hasActions={true}
                                                    likeVideoFunc={null}
                                                    setSinglePackage={null}
                                                    showDeleteModal={showDeleteModal}
                                                    saveVideoStats={null}
                                                    index={index}
                                                    setSingleVideo={setSingleVideo}
                                                    handlePlayed={null}
                                                    markVideoAsWatched={false}
                                                    pacakgeInfo={[]}
                                                    isPortfolioTestimonial={true}
                                                />
                                            </div>
                                        );
                                    })
                                    }
                                    {showDeleteModal ?
                                        <div id="modal-5" className="modal-container">
                                            <div className="modal">
                                                <div className="modal__header">
                                                    <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                                </div>
                                                <div className="modal__action">
                                                    <div className="button-group">
                                                        <button className="button button-m button-secondary"
                                                                type="button" onClick={() => {
                                                            setShowDeleteModal(false)
                                                        }}>No, Cancel
                                                        </button>
                                                        <button className="button button-m button-destructive"
                                                                type="button" onClick={() => deleteVideo()}>Yes, Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </>
                        : ''
                    }
                    {
                        showUploadVideo ?
                            <>
                                <div id="modal-5" className="modal-container">
                                    <div className="modal">
                                        <div className="modal__header">
                                            <h2 className="modal-title h4">{type.charAt(0).toUpperCase() + type.slice(1)} file
                                                upload</h2>
                                        </div>
                                        <div className="modal__content">
                                            {
                                                showProcessingVideo ?
                                                    <div className='fz-block-sm'>
                                                        <AlertMessage
                                                            messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                                            messageBody={""}
                                                            startDate={null}
                                                            setShowWarningContainerMessage={''}
                                                            messageType={"informational"}
                                                            showButton={false}
                                                        />
                                                    </div>
                                                    : ''
                                            }
                                            {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                                <>
                                                    <div
                                                        className={`form-field text-input ${!hasVideoName ? 'error' : ''}`}>
                                                        <label
                                                            htmlFor="project-description">{type.charAt(0).toUpperCase() + type.slice(1)} name</label>
                                                        <input
                                                            name="videoName"
                                                            id="videoName"
                                                            type="text"
                                                            aria-required="true"
                                                            placeholder=""
                                                            accept="video/mp4,video/x-m4v,video/*"
                                                            value={videoName}
                                                            onFocus={(e) => {
                                                                setHasVideoName(true);
                                                                setShowFormErrorMessage(false);
                                                            }}
                                                            onBlur={(e) => {
                                                                formValidator.clearEmojis(e.target.value, 'videoName');
                                                                checkVideoName(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setVideoName(e.target.value);
                                                            }}
                                                        >
                                                        </input>
                                                        {!hasVideoName ?
                                                            <p className="validation-message">Enter {type} name.</p>
                                                            : ''}
                                                    </div>
                                                    <div
                                                        className={`form-field text-area ${!hasProjectDescription ? 'error' : ''}`}>
                                                        <label htmlFor="project-description">Description</label>
                                                        <div className="input-container">
                                                        <textarea
                                                            name="Project description"
                                                            id="project-description"
                                                            type="text"
                                                            aria-required="true"
                                                            placeholder=""
                                                            value={projectDescription}
                                                            onFocus={(e) => {
                                                                setHasProjectDescription(true);
                                                                setShowFormErrorMessage(false);
                                                            }}
                                                            onBlur={(e) => {
                                                                formValidator.clearEmojis(e.target.value, 'projectDescription');
                                                                checkProjectDescription(e.target.value);
                                                            }}
                                                            onChange={(e) => {
                                                                setProjectDescription(e.target.value);
                                                            }}
                                                        >
                                                        </textarea>
                                                        </div>
                                                        {!hasProjectDescription ?
                                                            <p className="validation-message">Enter project
                                                                description</p>
                                                            : ''
                                                        }
                                                    </div>
                                                </>
                                                : ''
                                            }

                                            {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                                <div
                                                    className={`form-field file-input ${!isValidVideo || showErrorMessage || isTooLongVideo ? 'error' : ''}`}>
                                                    <input type="file" id="fileupload"
                                                           accept="video/mp4,video/x-m4v,video/*"
                                                           className="file-input__input" onChange={(e) => {
                                                        setShowErrorMessage(false);
                                                        setIsValidVideo(true);
                                                        changeHandlerUploads(e)
                                                    }} ref={videoRef}/>
                                                    <label htmlFor="fileupload"
                                                           className="button button-m button-tertiary">
                                                        {getSvg('upload')}
                                                        <span className="text">Choose video&hellip;</span>
                                                    </label>
                                                    {showErrorMessage ?
                                                        <p className="validation-message">Choose a valid video
                                                            first.</p>
                                                        : ''
                                                    }
                                                    {!isValidVideo ?
                                                        <p className="validation-message">{videoFormatError}</p>
                                                        : ''
                                                    }
                                                    {isTooLongVideo ?
                                                        <p className="validation-message">The video
                                                            must not exceed 10 minutes in length.</p>
                                                        : ''
                                                    }
                                                    {fileToBeUploaded !== '' ? <p>{fileToBeUploaded}</p> : ''}
                                                </div>
                                                : errorUploadingVideo ?
                                                    <div className="alert-container">
                                                        <div className="alert alert--error">
                                                            <div className="alert__content">
                                                                <p className="alert__heading">There was an error
                                                                    uploading
                                                                    the video. Please try it again.</p>
                                                            </div>
                                                            <button type="button"
                                                                    className="button-tertiary button-s button-icon alert__close"
                                                                    onClick={() => setErrorUploadingVideo(false)}>
                                                            <span className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17 7L7 17M7 7L17 17" stroke="currentColor"
                                                                          strokeWidth="2" strokeLinecap="round"
                                                                          strokeLinejoin="round"/>
                                                                </svg>
                                                            </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }

                                            <div className='file-input-container block'>
                                                <div className='file-input-container block'>
                                                    <div className="fz-block">
                                                        {
                                                            showProcessingVideo ?
                                                                <div className='fz-block'>
                                                                    <small className='fz-align-center'>
                                                                        Uploading video&nbsp; &nbsp; &nbsp;
                                                                        <progress className='progress-bar'
                                                                                  value={fileProgressSelfVideo}
                                                                                  max='100'></progress>
                                                                        &nbsp; &nbsp; &nbsp;
                                                                        {parseInt(fileProgressSelfVideo)} of 100%
                                                                        completed&nbsp;&nbsp;&nbsp;
                                                                        <span className="loader loader--s"></span>
                                                                    </small>
                                                                </div>
                                                                : ''
                                                        }
                                                    </div>
                                                    {showProcessing ?
                                                        <AlertMessage
                                                            messageHeader={processingMessage}
                                                            messageBody={messageWaiting}
                                                            startDate={null}
                                                            setShowWarningContainerMessage={''}
                                                            messageType={"informational"}
                                                            showButton={false}
                                                            showLoader={true}
                                                        />
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                            {showFormErrorMessage ?
                                                <AlertMessage
                                                    messageHeader={`${type.charAt(0).toUpperCase()}${type.slice(1)} name and description are required.`}
                                                    messageType={"error"}
                                                    showButton={true}
                                                    messageBodyContinuation={null}
                                                    onButtonClick={handleAlertButtonClick}
                                                />
                                                : ''
                                            }
                                        </div>
                                        {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                            <div className="modal__action">
                                                <div className="button-group">
                                                    <button
                                                        type='button'
                                                        id='uploadbutton'
                                                        className='button button-m button-primary'
                                                        onClick={() => {
                                                            handleUpload()
                                                        }}
                                                    >
                                                        {getSvg('upload')}
                                                        Upload video
                                                    </button>

                                                    <button
                                                        type='reset'
                                                        aria-label='Exit'
                                                        value='Exit'
                                                        className='button button-m butto-secondary'
                                                        onClick={() => {
                                                            setShowUploadVideo(false);
                                                            setShowErrorMessage(false);
                                                            setIsTooLongVideo(false);
                                                            setVideoName('');
                                                            setProjectDescription('');
                                                            setIsValidVideo(true);
                                                        }}
                                                    >
                                                        Exit
                                                    </button>
                                                </div>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </>
                            : ''
                    }
                </div>
            </main>
            {showPortfolioModal && (
                // <div className="modal-container">
                //     <div className="modal modal-lg">
                //         <div className="modal__header">
                //             <p></p>
                //             <button type="button" className="button-tertiary button-s button-icon modal-close"
                //                     onClick={() => {
                //                         setShowPortfolioModal(false)
                //                     }}>
                //                 {getSvg('close')}
                //             </button>
                //         </div>
                //         <div className="modal__content">
                //             <div className="fz-jc-center fz-block">
                //                 <svg width="219" height="192" viewBox="0 0 219 192" fill="none"
                //                      xmlns="http://www.w3.org/2000/svg">
                //                     <g clip-path="url(#clip0_8957_10399)">
                //                         <path
                //                             d="M130.185 190.257L126.398 188.664L123.801 169.69H89.2111L86.3957 188.586L83.0081 190.279C82.8461 190.36 82.7161 190.494 82.6393 190.658C82.5625 190.822 82.5434 191.007 82.585 191.183C82.6266 191.359 82.7266 191.516 82.8687 191.629C83.0108 191.741 83.1867 191.802 83.3679 191.802H129.873C130.058 191.802 130.238 191.738 130.382 191.621C130.526 191.503 130.624 191.339 130.661 191.158C130.698 190.976 130.67 190.786 130.584 190.622C130.497 190.458 130.356 190.329 130.185 190.257Z"
                //                             fill="#E6E6E6"/>
                //                         <path
                //                             d="M191.653 172.214H21.7386C20.8472 172.212 19.9929 171.857 19.3632 171.226C18.7335 170.595 18.3799 169.741 18.3799 168.849V144.899H195.012V168.849C195.012 169.741 194.658 170.595 194.028 171.226C193.399 171.857 192.544 172.212 191.653 172.214Z"
                //                             fill="#CCCCCC"/>
                //                         <path
                //                             d="M195.189 156.219H18.2246V53.7418C18.2259 52.6664 18.6538 51.6354 19.4146 50.8749C20.1754 50.1145 21.2069 49.6868 22.2828 49.6855H191.131C192.207 49.6867 193.238 50.1145 193.999 50.8749C194.76 51.6354 195.188 52.6664 195.189 53.7418V156.219Z"
                //                             fill="#3F3D56"/>
                //                         <path
                //                             d="M184.62 148.781H28.7941C27.9646 148.78 27.1694 148.45 26.5829 147.864C25.9964 147.278 25.6665 146.483 25.6655 145.654V60.2504C25.6665 59.4213 25.9964 58.6264 26.5829 58.0401C27.1694 57.4538 27.9646 57.124 28.7941 57.123H184.62C185.449 57.124 186.244 57.4538 186.831 58.0401C187.417 58.6264 187.747 59.4213 187.748 60.2504V145.654C187.747 146.483 187.417 147.278 186.831 147.864C186.244 148.45 185.449 148.78 184.62 148.781Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M150.409 192H62.848C62.7583 192.002 62.6705 191.974 62.5983 191.921C62.5261 191.868 62.4734 191.792 62.4487 191.706C62.4332 191.647 62.4314 191.586 62.4434 191.526C62.4554 191.467 62.4809 191.411 62.5179 191.363C62.5549 191.315 62.6025 191.276 62.657 191.249C62.7114 191.222 62.7713 191.208 62.832 191.208H150.376C150.471 191.205 150.564 191.234 150.64 191.29C150.716 191.346 150.771 191.427 150.796 191.518C150.808 191.576 150.808 191.636 150.795 191.694C150.781 191.751 150.755 191.805 150.718 191.852C150.681 191.898 150.634 191.935 150.58 191.961C150.527 191.987 150.468 192 150.409 192Z"
                //                             fill="#CCCCCC"/>
                //                         <path
                //                             d="M166.957 133.577H134.126C134.044 133.584 133.963 133.559 133.9 133.506C133.837 133.454 133.797 133.379 133.788 133.298V106.827C133.797 106.745 133.837 106.67 133.9 106.618C133.963 106.566 134.044 106.54 134.126 106.547H166.957C167.039 106.54 167.12 106.566 167.183 106.618C167.246 106.67 167.287 106.745 167.295 106.827V133.298C167.287 133.379 167.246 133.454 167.183 133.506C167.12 133.559 167.039 133.584 166.957 133.577Z"
                //                             fill="#F0F0F0"/>
                //                         <path
                //                             d="M163.389 109.476H137.695C137.605 109.476 137.519 109.511 137.456 109.575C137.393 109.638 137.357 109.724 137.357 109.814V129.714C137.357 129.804 137.393 129.889 137.456 129.953C137.519 130.016 137.605 130.052 137.695 130.052H163.389C163.478 130.052 163.564 130.016 163.628 129.953C163.691 129.889 163.727 129.804 163.727 129.714V109.814C163.727 109.724 163.691 109.638 163.628 109.575C163.564 109.511 163.478 109.476 163.389 109.476Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M149.907 122.961C151.711 122.961 153.174 121.499 153.174 119.695C153.174 117.892 151.711 116.43 149.907 116.43C148.103 116.43 146.64 117.892 146.64 119.695C146.64 121.499 148.103 122.961 149.907 122.961Z"
                //                             fill="#FFB8B8"/>
                //                         <path
                //                             d="M154.686 130.052H147.443L147.68 124.729L147.736 124.67L148.549 123.812H151.136L152.095 124.74L152.172 124.815L154.213 126.77L154.686 130.052Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M148.689 130.052H143.209C143.196 128.851 143.188 128.131 143.188 128.131L143.505 127.938L143.507 127.937H143.508L147 125.818L147.733 124.628L147.737 124.67L147.952 127.179C147.952 127.179 148.308 128.329 148.689 130.052Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M157.899 130.052H152.095C152.183 126.548 151.709 124.628 151.953 124.628L152.095 124.74L153.805 126.09L156.06 126.917H156.061L157.616 127.487L157.889 127.587V127.715C157.889 128.023 157.891 128.865 157.899 130.052Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M146.816 120.717C146.816 120.717 147.094 120.23 147.029 118.602C146.965 116.974 149.444 117.895 151.516 117.739C153.588 117.584 153.15 120.457 153.15 120.457C153.15 120.457 153.299 120.286 153.619 118.9C153.939 117.513 153.213 116.132 153.213 116.132C152.999 114.675 151.845 115.142 151.845 115.142C151.991 115.179 152.118 115.269 152.201 115.394C152.284 115.519 152.317 115.671 152.294 115.82C151.824 114.996 150.67 115.463 150.67 115.463C149.132 114.498 147.573 115.769 147.573 115.769C145.715 116.19 146.805 117.667 146.805 117.667C144.936 118.611 146.816 120.717 146.816 120.717Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M143.941 130.052H141.689C141.707 129.775 141.707 129.497 141.691 129.219C141.556 128.684 143.448 127.96 143.505 127.938L143.507 127.938H143.508L143.868 128.131L143.941 130.052Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M158.305 130.052H155.94L155.645 127.138L155.976 126.896C155.976 126.896 156.006 126.903 156.06 126.917H156.061C156.602 127.04 157.124 127.231 157.616 127.487C157.72 127.545 157.812 127.623 157.888 127.715C157.935 127.771 157.958 127.842 157.953 127.915C157.941 128.024 158.079 128.826 158.305 130.052Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M166.85 99.8027H134.019C133.937 99.8096 133.856 99.7841 133.793 99.7318C133.73 99.6795 133.69 99.6045 133.681 99.523V73.0426C133.69 72.961 133.73 72.886 133.793 72.8337C133.856 72.7814 133.937 72.7559 134.019 72.7628H166.85C166.932 72.7559 167.013 72.7814 167.076 72.8337C167.139 72.886 167.18 72.961 167.188 73.0426V99.523C167.18 99.6045 167.139 99.6795 167.076 99.7318C167.013 99.7841 166.932 99.8096 166.85 99.8027Z"
                //                             fill="#F0F0F0"/>
                //                         <path
                //                             d="M163.282 75.6914H137.588C137.498 75.6915 137.413 75.7272 137.349 75.7905C137.286 75.8539 137.25 75.9398 137.25 76.0293V95.9298C137.25 96.0194 137.286 96.1053 137.349 96.1686C137.413 96.232 137.498 96.2676 137.588 96.2678H163.282C163.371 96.2676 163.457 96.232 163.521 96.1686C163.584 96.1053 163.62 96.0194 163.62 95.9298V76.0293C163.62 75.9398 163.584 75.8539 163.521 75.7905C163.457 75.7272 163.371 75.6915 163.282 75.6914Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M156.759 96.2683C156.904 95.7464 157.017 95.2965 157.074 94.9781C157.092 94.8799 157.094 94.7795 157.08 94.6808C156.863 93.142 152.955 91.8414 152.454 91.6807L152.333 90.3409L147.414 89.8115L146.791 91.5252L145.025 92.1868C144.962 92.2104 144.901 92.2417 144.844 92.2799C144.696 92.3799 144.582 92.5228 144.518 92.6894C144.454 92.856 144.442 93.0384 144.485 93.2119L145.237 96.2683L156.759 96.2683Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M146.561 96.2682H142.744C142.881 95.4166 143.003 94.6634 143.083 94.1843C143.374 92.4405 144.646 92.2918 144.845 92.2798C144.86 92.2783 144.87 92.2783 144.871 92.2783H145.674L146.561 96.2682Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M156.759 96.2686H157.685C157.64 95.6924 157.43 95.1413 157.08 94.6811C156.905 94.4493 156.678 94.261 156.418 94.1306L156.348 94.1006L155.535 96.2686H156.759Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M149.682 88.9639C151.638 88.9639 153.224 87.3787 153.224 85.4233C153.224 83.468 151.638 81.8828 149.682 81.8828C147.725 81.8828 146.14 83.468 146.14 85.4233C146.14 87.3787 147.725 88.9639 149.682 88.9639Z"
                //                             fill="#A0616A"/>
                //                         <path
                //                             d="M147.304 83.6981C147.671 84.1319 148.291 84.2542 148.863 84.304C150.202 84.3779 151.544 84.2684 152.852 83.9785C152.934 84.7639 152.712 85.611 153.175 86.2572C153.392 85.4749 153.518 84.6702 153.551 83.859C153.586 83.511 153.54 83.1597 153.418 82.8319C153.353 82.6676 153.243 82.525 153.101 82.4201C152.958 82.3152 152.79 82.2524 152.613 82.2387C152.877 82.0386 153.186 81.9065 153.513 81.8542L152.389 81.2892L152.677 80.9928L150.641 80.8683L151.231 80.4938C150.343 80.372 149.445 80.3437 148.551 80.4096C148.133 80.4098 147.724 80.5236 147.366 80.7389C147.03 80.9792 146.836 81.4568 147.044 81.8096C146.783 81.8525 146.537 81.9613 146.33 82.1258C146.122 82.2903 145.96 82.5051 145.859 82.7497C145.731 83.1385 145.7 83.5526 145.769 83.9561C145.846 84.6034 145.996 85.2398 146.218 85.8529"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M46.1187 132.837V72.9586C46.1396 72.7728 46.2326 72.6026 46.3775 72.4844C46.5225 72.3662 46.7079 72.3094 46.8943 72.3261H122.214C122.4 72.3094 122.586 72.3662 122.731 72.4844C122.876 72.6026 122.969 72.7728 122.99 72.9586V132.837C122.969 133.023 122.876 133.193 122.731 133.311C122.586 133.429 122.4 133.486 122.214 133.469H46.8943C46.7079 133.486 46.5225 133.429 46.3775 133.311C46.2326 133.193 46.1396 133.023 46.1187 132.837Z"
                //                             fill="#E4E4E4"/>
                //                         <path
                //                             d="M54.3057 79.8212V125.476C54.306 125.682 54.3878 125.879 54.5332 126.024C54.6786 126.17 54.8757 126.251 55.0813 126.252H114.027C114.233 126.251 114.43 126.17 114.575 126.024C114.721 125.879 114.802 125.682 114.803 125.476V79.8212C114.802 79.6157 114.721 79.4186 114.575 79.2733C114.43 79.128 114.233 79.0462 114.027 79.0459H55.0813C54.8757 79.0462 54.6786 79.128 54.5332 79.2733C54.3878 79.4186 54.306 79.6157 54.3057 79.8212Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M78.7544 126.252H90.6177L89.8783 115.16L85.5987 116.147L81.4363 114.547L81.3259 114.505L81.319 114.502C81.3175 114.505 81.3164 114.509 81.3156 114.512C81.2501 114.776 79.9436 120.005 78.7544 126.252Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M71.605 116.971L72.7132 126.251H82.7117L81.4364 114.546L81.4242 114.433L81.3261 114.505L81.3157 114.512L78.8491 116.311L71.605 116.971Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M88.8633 126.252H97.8172L97.9464 118.945L91.3573 116.761L89.8785 115.16C89.8785 115.16 89.4114 119.975 88.8633 126.252Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M67.2944 126.252H74.7144L74.2387 119.603L71.6051 116.971C71.6051 116.971 69.5213 121.34 67.2944 126.252Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M95.7056 126.251H99.6198C99.6026 124.121 99.5922 122.65 99.5922 122.235C99.6118 121.593 99.4717 120.956 99.1844 120.382C98.8971 119.808 98.4716 119.314 97.9462 118.944L95.9693 119.602L95.7056 126.251Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M96.3162 111.67H75.5752V102.932C75.5752 99.9372 77.6859 96.3367 80.2802 94.9058C83.9216 92.8977 87.9025 92.8624 91.202 94.8095C92.7551 95.7287 94.0428 97.0352 94.9393 98.6011C95.8357 100.167 96.3102 101.939 96.3162 103.743V111.67Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M92.9297 107.557C94.9142 103.929 93.58 99.3786 89.9496 97.3949C86.3192 95.4112 81.7674 96.7448 79.7828 100.374C77.7983 104.003 79.1325 108.552 82.7629 110.536C86.3933 112.52 90.9451 111.186 92.9297 107.557Z"
                //                             fill="#FFB8B8"/>
                //                         <path
                //                             d="M93.4775 104.352H82.7898L82.7645 104.231C82.6408 103.594 82.4809 102.965 82.2857 102.346C81.8613 102.968 81.4699 103.611 81.1132 104.274L81.0696 104.352H78.9919C78.8644 104.352 78.7383 104.325 78.6217 104.274C78.5051 104.222 78.4005 104.147 78.3147 104.053C78.2582 103.991 78.1259 103.845 78.6732 97.1675C78.6882 97.0085 78.7444 96.8561 78.836 96.7253C78.9277 96.5945 79.0518 96.4897 79.1962 96.4212C83.8857 94.233 88.6378 94.2909 93.3218 96.5942H93.4053L93.4346 96.7103C94.3587 103.818 94.2258 103.971 94.169 104.037C94.0831 104.136 93.9769 104.215 93.8576 104.27C93.7383 104.324 93.6086 104.352 93.4775 104.352Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M49.8954 82.1836L38.4968 183.646C38.4251 184.506 32.6541 184.972 32.6899 184.183L32.9767 80.9654C32.9767 80.0698 33.8011 79.3532 34.6972 79.4249L48.6408 80.6072C49.3577 80.7147 49.9312 81.3595 49.8954 82.1836Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M61.7238 130.908L56.0604 183.646C55.9887 184.506 50.2535 184.971 50.2535 184.183L50.0026 80.9651C50.0026 80.1052 61.7596 130.12 61.7238 130.908Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M56.6696 183.181L57.0997 185.474C57.5657 186.369 64.3045 189.379 64.3045 189.379C64.8063 189.558 65.4157 190.095 65.4515 190.669C65.4874 191.421 64.6629 192.03 64.0177 192.03H50.6835C49.4648 192.03 48.5328 190.74 48.7837 189.415L49.8949 183.216H56.6696V183.181Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M38.6758 183.18C38.6758 183.18 38.0664 187.73 38.389 189.091C38.7475 190.453 38.8908 192.065 35.5931 192.065C32.2954 192.065 28.0657 192.065 28.0657 192.065C28.0657 192.065 25.9509 190.417 27.8865 188.769L29.3203 188.59L31.7936 183.072L38.6758 183.18Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M41.9738 11.5684C41.9738 11.5684 36.4895 20.2386 32.009 22.7823C26.5606 25.8634 23.9081 29.9836 24.4457 34.9994C25.0193 40.0152 14.6602 42.3439 19.7501 52.5188C24.0156 61.0099 42.9775 56.0299 42.9775 56.0299L58.4265 43.5262C58.4265 43.5262 67.3877 43.3113 63.7315 33.423C62.1543 29.1237 67.5669 26.9024 65.3445 21.5642C62.6203 15.0078 57.9247 10.3145 57.9247 10.3145L41.9738 11.5684Z"
                //                             fill="#7D5034"/>
                //                         <path
                //                             d="M57.9607 37.3283C57.9607 37.3283 54.7347 20.4537 48.7128 21.7793C42.6909 23.0691 44.0888 32.1692 36.8841 34.6412C30.0019 37.0058 25.5571 42.7382 30.9338 49.5453C34.3749 53.8804 50.5767 48.0764 51.2219 48.0764C51.8671 48.0406 57.9607 37.3283 57.9607 37.3283Z"
                //                             fill="#40291B"/>
                //                         <path
                //                             d="M75.9543 67.423L74.9507 68.032C73.1584 69.1785 70.9002 68.6769 69.9683 66.993L57.7094 44.7802C56.7774 43.0605 57.4585 40.7317 59.2507 39.6211L60.2544 39.012C62.0466 37.8656 64.8066 39.7286 65.7386 41.4124L77.4956 62.228C78.4634 63.9477 77.7824 66.2765 75.9543 67.423Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M34.4822 80.1416C34.4822 80.1416 28.066 90.2807 30.5751 100.42C33.0842 110.559 33.0842 110.559 33.0842 110.559L46.8128 109.377L48.9993 83.8676L34.4822 80.1416Z"
                //                             fill="#385680"/>
                //                         <path
                //                             d="M63.9102 140.905H33.3705C30.3595 140.905 27.9579 138.361 28.173 135.352L30.5746 100.456L43.2278 77.2041H63.7668L63.9102 140.905Z"
                //                             fill="#385680"/>
                //                         <path
                //                             d="M51.5794 33.8526C47.063 33.5659 43.4785 29.625 43.6219 25.0749L43.9445 15.3299C44.0879 10.7799 47.9232 7.26882 52.4397 7.55544C56.9561 7.84205 60.5406 11.783 60.3972 16.3331L60.0746 26.0781C59.9312 30.6281 56.0959 34.1392 51.5794 33.8526Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M51.0063 50.8349C43.8373 51.3723 33.3349 42.7379 30.5031 40.8033L42.9054 36.2532C44.375 34.426 44.8052 36.2891 45.1636 36.3965C45.4862 36.2532 46.92 36.2174 48.0312 36.5398C51.0421 37.3639 55.5586 37.758 58.928 38.6895C61.3654 39.5851 61.5805 42.7021 61.5088 43.2037C61.0786 46.8581 56.3471 50.4408 51.0063 50.8349Z"
                //                             fill="#EBB85B"/>
                //                         <path
                //                             d="M45.9877 40.0148H46.597C48.4968 40.0506 50.6116 39.2624 50.6475 37.8293L50.3966 27.4394C50.4324 26.0422 48.8911 24.8599 46.9555 24.8241H46.3461C44.4463 24.7882 42.8334 25.9347 42.7975 27.332L41.9372 37.901C41.9014 39.3699 44.052 39.9789 45.9877 40.0148Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M39.7514 24.7893C40.3966 26.6523 41.8662 27.7271 42.9774 27.1897C44.0886 26.6165 44.4829 24.646 43.8377 22.783C43.1925 20.92 41.7228 19.8452 40.6116 20.3826C39.4646 20.9558 39.1062 22.9263 39.7514 24.7893Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M52.9062 10.8519C52.9062 10.8519 60.5236 13.6464 60.3802 18.7338C60.3802 18.7338 64.4482 6.87505 53.4439 6.19434L52.9062 10.8519Z"
                //                             fill="#7D5034"/>
                //                         <path
                //                             d="M52.9569 10.9069C52.9569 10.9069 44.7537 15.5263 44.8766 22.1689L38.7933 22.6746C38.7933 22.6746 35.7773 3.90128 53.4434 6.19415L52.9569 10.9069Z"
                //                             fill="#7D5034"/>
                //                         <path
                //                             d="M58.2829 78.3145L41.651 77.8487C38.0665 77.992 30.7184 76.8814 30.7184 73.9077L30.5391 47.8614C30.5033 44.8877 33.4067 42.3798 37.027 42.2365L58.032 41.4841C61.6165 41.3408 61.043 43.9562 61.0788 46.9299L63.6596 72.9046C63.6955 75.914 61.9032 78.1711 58.2829 78.3145Z"
                //                             fill="#EBB85B"/>
                //                         <path
                //                             d="M61.7235 89.2776C63.4082 88.2745 64.376 86.3398 64.1968 84.2977L62.763 67.3514C68.1039 60.6876 66.5626 54.7403 63.0856 45.8193L63.0139 42.559C63.1573 40.6244 61.9386 38.9046 60.1463 38.5105L56.3468 37.6865L54.5546 91.3914L61.7235 89.2776Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M50.2897 36.2175C50.0747 37.758 49.537 38.9045 48.2466 39.5494L50.8991 43.992C52.7989 42.8455 53.4799 40.7675 53.3365 37.2923C53.3007 36.6116 52.8347 36.0025 52.1537 35.8234L51.2576 35.5726C50.7916 35.4651 50.3256 35.7876 50.2897 36.2175Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M46.3459 44.529L48.7476 39.7282C45.414 38.7966 43.6218 38.1159 42.7256 36.4321C42.2597 35.5722 41.256 35.1781 40.3599 35.5722C39.3562 36.0021 38.8903 37.1844 39.4279 38.1876C40.5391 40.3014 42.9407 42.2719 46.3459 44.529Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M33.6934 79.9977C35.7365 81.5024 33.1198 72.5456 35.0196 69.6436L34.6253 51.8734C36.5251 49.0072 36.0591 44.8154 34.1593 42.1642L33.8009 41.5551C31.7577 40.0504 27.5281 41.3043 25.6283 44.1705L21.1836 74.5519C21.1836 85.4792 33.6934 79.9977 33.6934 79.9977Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M55.3073 36.8619C55.3073 36.8619 53.4792 35.8229 53.6226 37.5785C56.0242 45.9262 56.2034 56.2086 55.1998 67.53L60.9707 56.2444L58.4258 50.2971L61.616 38.7249L55.3073 36.8619Z"
                //                             fill="#385680"/>
                //                         <path
                //                             d="M39.4281 36.8623C39.4281 36.8623 44.7331 52.2322 44.8764 63.1953C45.0198 74.1584 44.9482 91.1046 44.9482 91.1046C44.9482 91.1046 31.0404 90.5672 30.0009 85.1215C28.9973 79.7116 30.5386 40.8033 30.5386 40.8033L39.4281 36.8623Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M55.128 67.674L50.4323 68.928C48.8552 69.2504 48.9269 70.4685 49.2495 72.2599C49.5721 74.0513 49.7513 74.9469 51.3284 74.6245L56.3467 74.1946C57.9239 73.8721 58.9634 72.1524 58.6049 70.3611C58.2823 68.5339 56.7051 67.3516 55.128 67.674Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M48.2816 68.9277L29.8934 70.2175C28.1728 70.3608 25.4845 71.6505 25.592 73.5852C25.592 73.5852 24.66 79.3892 30.7178 78.9235L49.1061 74.875C50.8266 74.7317 51.6152 73.6569 51.5077 71.7222C51.436 69.7517 50.0022 68.7485 48.2816 68.9277Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M38.174 36.97C38.174 36.97 39.8228 36.0027 40.0737 37.7582C42.8338 46.4284 45.1278 55.7792 44.9128 68.462L37.8155 56.4241L40.2888 49.8319L33.3708 39.4421L38.174 36.97Z"
                //                             fill="#385680"/>
                //                         <path
                //                             d="M57.5658 81.6462H71.2226C74.3411 81.6462 77.7105 79.1383 78.6783 76.0572L86.349 51.9455C87.3168 48.8643 85.5963 46.3564 82.442 46.3564H68.7851C65.6667 46.3564 62.2973 48.8643 61.3295 51.9455L53.6587 76.0572C52.6909 79.1383 54.4473 81.6462 57.5658 81.6462Z"
                //                             fill="#DDDDDD"/>
                //                         <path
                //                             d="M85.4169 61.4038L84.8793 60.3648C83.9831 58.6809 81.6532 59.6482 80.7571 62.192C80.7213 62.2995 80.6854 62.4069 80.6496 62.5144C80.0044 64.5924 80.5779 66.4912 81.94 66.742L83.4096 66.8495C84.8076 66.5629 86.7432 61.6546 85.4169 61.4038Z"
                //                             fill="#D48460"/>
                //                     </g>
                //                     <defs>
                //                         <clipPath id="clip0_8957_10399">
                //                             <rect width="219" height="192" fill="white"/>
                //                         </clipPath>
                //                     </defs>
                //                 </svg>
                //
                //             </div>
                //             <h2 className="modal-title h4">Pro/Vendor Portfolio</h2>
                //             <p>You can showcase your work through videos of past projects. Property Owners will be able to view these video portfolios only after you've reached out to them.</p>
                //         </div>
                //         <div className="modal__action">
                //             <div className="button-group">
                //                 <button className="button button-m button-primary modal-close" type="button"
                //                         onClick={() => setShowPortfolioModal(false)}>Close
                //                 </button>
                //             </div>
                //         </div>
                //     </div>
                // </div>
                <VideoModal videoUrl={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Pro+Vendor+-+Portfolio.mp4'} onClose={handleClosePortfolioVideoModal} />
            )}
            {showTestimonialModal && (
                // <div className="modal-container">
                //     <div className="modal modal-lg">
                //         <div className="modal__header">
                //             <p></p>
                //             <button type="button" className="button-tertiary button-s button-icon modal-close"
                //                     onClick={() => {
                //                         setShowTestimonialModal(false)
                //                     }}>
                //                 {getSvg('close')}
                //             </button>
                //         </div>
                //         <div className="modal__content">
                //             <div className="fz-jc-center fz-block">
                //                 <svg width="219" height="192" viewBox="0 0 219 192" fill="none"
                //                      xmlns="http://www.w3.org/2000/svg">
                //                     <g clip-path="url(#clip0_8957_10399)">
                //                         <path
                //                             d="M130.185 190.257L126.398 188.664L123.801 169.69H89.2111L86.3957 188.586L83.0081 190.279C82.8461 190.36 82.7161 190.494 82.6393 190.658C82.5625 190.822 82.5434 191.007 82.585 191.183C82.6266 191.359 82.7266 191.516 82.8687 191.629C83.0108 191.741 83.1867 191.802 83.3679 191.802H129.873C130.058 191.802 130.238 191.738 130.382 191.621C130.526 191.503 130.624 191.339 130.661 191.158C130.698 190.976 130.67 190.786 130.584 190.622C130.497 190.458 130.356 190.329 130.185 190.257Z"
                //                             fill="#E6E6E6"/>
                //                         <path
                //                             d="M191.653 172.214H21.7386C20.8472 172.212 19.9929 171.857 19.3632 171.226C18.7335 170.595 18.3799 169.741 18.3799 168.849V144.899H195.012V168.849C195.012 169.741 194.658 170.595 194.028 171.226C193.399 171.857 192.544 172.212 191.653 172.214Z"
                //                             fill="#CCCCCC"/>
                //                         <path
                //                             d="M195.189 156.219H18.2246V53.7418C18.2259 52.6664 18.6538 51.6354 19.4146 50.8749C20.1754 50.1145 21.2069 49.6868 22.2828 49.6855H191.131C192.207 49.6867 193.238 50.1145 193.999 50.8749C194.76 51.6354 195.188 52.6664 195.189 53.7418V156.219Z"
                //                             fill="#3F3D56"/>
                //                         <path
                //                             d="M184.62 148.781H28.7941C27.9646 148.78 27.1694 148.45 26.5829 147.864C25.9964 147.278 25.6665 146.483 25.6655 145.654V60.2504C25.6665 59.4213 25.9964 58.6264 26.5829 58.0401C27.1694 57.4538 27.9646 57.124 28.7941 57.123H184.62C185.449 57.124 186.244 57.4538 186.831 58.0401C187.417 58.6264 187.747 59.4213 187.748 60.2504V145.654C187.747 146.483 187.417 147.278 186.831 147.864C186.244 148.45 185.449 148.78 184.62 148.781Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M150.409 192H62.848C62.7583 192.002 62.6705 191.974 62.5983 191.921C62.5261 191.868 62.4734 191.792 62.4487 191.706C62.4332 191.647 62.4314 191.586 62.4434 191.526C62.4554 191.467 62.4809 191.411 62.5179 191.363C62.5549 191.315 62.6025 191.276 62.657 191.249C62.7114 191.222 62.7713 191.208 62.832 191.208H150.376C150.471 191.205 150.564 191.234 150.64 191.29C150.716 191.346 150.771 191.427 150.796 191.518C150.808 191.576 150.808 191.636 150.795 191.694C150.781 191.751 150.755 191.805 150.718 191.852C150.681 191.898 150.634 191.935 150.58 191.961C150.527 191.987 150.468 192 150.409 192Z"
                //                             fill="#CCCCCC"/>
                //                         <path
                //                             d="M166.957 133.577H134.126C134.044 133.584 133.963 133.559 133.9 133.506C133.837 133.454 133.797 133.379 133.788 133.298V106.827C133.797 106.745 133.837 106.67 133.9 106.618C133.963 106.566 134.044 106.54 134.126 106.547H166.957C167.039 106.54 167.12 106.566 167.183 106.618C167.246 106.67 167.287 106.745 167.295 106.827V133.298C167.287 133.379 167.246 133.454 167.183 133.506C167.12 133.559 167.039 133.584 166.957 133.577Z"
                //                             fill="#F0F0F0"/>
                //                         <path
                //                             d="M163.389 109.476H137.695C137.605 109.476 137.519 109.511 137.456 109.575C137.393 109.638 137.357 109.724 137.357 109.814V129.714C137.357 129.804 137.393 129.889 137.456 129.953C137.519 130.016 137.605 130.052 137.695 130.052H163.389C163.478 130.052 163.564 130.016 163.628 129.953C163.691 129.889 163.727 129.804 163.727 129.714V109.814C163.727 109.724 163.691 109.638 163.628 109.575C163.564 109.511 163.478 109.476 163.389 109.476Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M149.907 122.961C151.711 122.961 153.174 121.499 153.174 119.695C153.174 117.892 151.711 116.43 149.907 116.43C148.103 116.43 146.64 117.892 146.64 119.695C146.64 121.499 148.103 122.961 149.907 122.961Z"
                //                             fill="#FFB8B8"/>
                //                         <path
                //                             d="M154.686 130.052H147.443L147.68 124.729L147.736 124.67L148.549 123.812H151.136L152.095 124.74L152.172 124.815L154.213 126.77L154.686 130.052Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M148.689 130.052H143.209C143.196 128.851 143.188 128.131 143.188 128.131L143.505 127.938L143.507 127.937H143.508L147 125.818L147.733 124.628L147.737 124.67L147.952 127.179C147.952 127.179 148.308 128.329 148.689 130.052Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M157.899 130.052H152.095C152.183 126.548 151.709 124.628 151.953 124.628L152.095 124.74L153.805 126.09L156.06 126.917H156.061L157.616 127.487L157.889 127.587V127.715C157.889 128.023 157.891 128.865 157.899 130.052Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M146.816 120.717C146.816 120.717 147.094 120.23 147.029 118.602C146.965 116.974 149.444 117.895 151.516 117.739C153.588 117.584 153.15 120.457 153.15 120.457C153.15 120.457 153.299 120.286 153.619 118.9C153.939 117.513 153.213 116.132 153.213 116.132C152.999 114.675 151.845 115.142 151.845 115.142C151.991 115.179 152.118 115.269 152.201 115.394C152.284 115.519 152.317 115.671 152.294 115.82C151.824 114.996 150.67 115.463 150.67 115.463C149.132 114.498 147.573 115.769 147.573 115.769C145.715 116.19 146.805 117.667 146.805 117.667C144.936 118.611 146.816 120.717 146.816 120.717Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M143.941 130.052H141.689C141.707 129.775 141.707 129.497 141.691 129.219C141.556 128.684 143.448 127.96 143.505 127.938L143.507 127.938H143.508L143.868 128.131L143.941 130.052Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M158.305 130.052H155.94L155.645 127.138L155.976 126.896C155.976 126.896 156.006 126.903 156.06 126.917H156.061C156.602 127.04 157.124 127.231 157.616 127.487C157.72 127.545 157.812 127.623 157.888 127.715C157.935 127.771 157.958 127.842 157.953 127.915C157.941 128.024 158.079 128.826 158.305 130.052Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M166.85 99.8027H134.019C133.937 99.8096 133.856 99.7841 133.793 99.7318C133.73 99.6795 133.69 99.6045 133.681 99.523V73.0426C133.69 72.961 133.73 72.886 133.793 72.8337C133.856 72.7814 133.937 72.7559 134.019 72.7628H166.85C166.932 72.7559 167.013 72.7814 167.076 72.8337C167.139 72.886 167.18 72.961 167.188 73.0426V99.523C167.18 99.6045 167.139 99.6795 167.076 99.7318C167.013 99.7841 166.932 99.8096 166.85 99.8027Z"
                //                             fill="#F0F0F0"/>
                //                         <path
                //                             d="M163.282 75.6914H137.588C137.498 75.6915 137.413 75.7272 137.349 75.7905C137.286 75.8539 137.25 75.9398 137.25 76.0293V95.9298C137.25 96.0194 137.286 96.1053 137.349 96.1686C137.413 96.232 137.498 96.2676 137.588 96.2678H163.282C163.371 96.2676 163.457 96.232 163.521 96.1686C163.584 96.1053 163.62 96.0194 163.62 95.9298V76.0293C163.62 75.9398 163.584 75.8539 163.521 75.7905C163.457 75.7272 163.371 75.6915 163.282 75.6914Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M156.759 96.2683C156.904 95.7464 157.017 95.2965 157.074 94.9781C157.092 94.8799 157.094 94.7795 157.08 94.6808C156.863 93.142 152.955 91.8414 152.454 91.6807L152.333 90.3409L147.414 89.8115L146.791 91.5252L145.025 92.1868C144.962 92.2104 144.901 92.2417 144.844 92.2799C144.696 92.3799 144.582 92.5228 144.518 92.6894C144.454 92.856 144.442 93.0384 144.485 93.2119L145.237 96.2683L156.759 96.2683Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M146.561 96.2682H142.744C142.881 95.4166 143.003 94.6634 143.083 94.1843C143.374 92.4405 144.646 92.2918 144.845 92.2798C144.86 92.2783 144.87 92.2783 144.871 92.2783H145.674L146.561 96.2682Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M156.759 96.2686H157.685C157.64 95.6924 157.43 95.1413 157.08 94.6811C156.905 94.4493 156.678 94.261 156.418 94.1306L156.348 94.1006L155.535 96.2686H156.759Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M149.682 88.9639C151.638 88.9639 153.224 87.3787 153.224 85.4233C153.224 83.468 151.638 81.8828 149.682 81.8828C147.725 81.8828 146.14 83.468 146.14 85.4233C146.14 87.3787 147.725 88.9639 149.682 88.9639Z"
                //                             fill="#A0616A"/>
                //                         <path
                //                             d="M147.304 83.6981C147.671 84.1319 148.291 84.2542 148.863 84.304C150.202 84.3779 151.544 84.2684 152.852 83.9785C152.934 84.7639 152.712 85.611 153.175 86.2572C153.392 85.4749 153.518 84.6702 153.551 83.859C153.586 83.511 153.54 83.1597 153.418 82.8319C153.353 82.6676 153.243 82.525 153.101 82.4201C152.958 82.3152 152.79 82.2524 152.613 82.2387C152.877 82.0386 153.186 81.9065 153.513 81.8542L152.389 81.2892L152.677 80.9928L150.641 80.8683L151.231 80.4938C150.343 80.372 149.445 80.3437 148.551 80.4096C148.133 80.4098 147.724 80.5236 147.366 80.7389C147.03 80.9792 146.836 81.4568 147.044 81.8096C146.783 81.8525 146.537 81.9613 146.33 82.1258C146.122 82.2903 145.96 82.5051 145.859 82.7497C145.731 83.1385 145.7 83.5526 145.769 83.9561C145.846 84.6034 145.996 85.2398 146.218 85.8529"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M46.1187 132.837V72.9586C46.1396 72.7728 46.2326 72.6026 46.3775 72.4844C46.5225 72.3662 46.7079 72.3094 46.8943 72.3261H122.214C122.4 72.3094 122.586 72.3662 122.731 72.4844C122.876 72.6026 122.969 72.7728 122.99 72.9586V132.837C122.969 133.023 122.876 133.193 122.731 133.311C122.586 133.429 122.4 133.486 122.214 133.469H46.8943C46.7079 133.486 46.5225 133.429 46.3775 133.311C46.2326 133.193 46.1396 133.023 46.1187 132.837Z"
                //                             fill="#E4E4E4"/>
                //                         <path
                //                             d="M54.3057 79.8212V125.476C54.306 125.682 54.3878 125.879 54.5332 126.024C54.6786 126.17 54.8757 126.251 55.0813 126.252H114.027C114.233 126.251 114.43 126.17 114.575 126.024C114.721 125.879 114.802 125.682 114.803 125.476V79.8212C114.802 79.6157 114.721 79.4186 114.575 79.2733C114.43 79.128 114.233 79.0462 114.027 79.0459H55.0813C54.8757 79.0462 54.6786 79.128 54.5332 79.2733C54.3878 79.4186 54.306 79.6157 54.3057 79.8212Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M78.7544 126.252H90.6177L89.8783 115.16L85.5987 116.147L81.4363 114.547L81.3259 114.505L81.319 114.502C81.3175 114.505 81.3164 114.509 81.3156 114.512C81.2501 114.776 79.9436 120.005 78.7544 126.252Z"
                //                             fill="#D0DDFF"/>
                //                         <path
                //                             d="M71.605 116.971L72.7132 126.251H82.7117L81.4364 114.546L81.4242 114.433L81.3261 114.505L81.3157 114.512L78.8491 116.311L71.605 116.971Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M88.8633 126.252H97.8172L97.9464 118.945L91.3573 116.761L89.8785 115.16C89.8785 115.16 89.4114 119.975 88.8633 126.252Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M67.2944 126.252H74.7144L74.2387 119.603L71.6051 116.971C71.6051 116.971 69.5213 121.34 67.2944 126.252Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M95.7056 126.251H99.6198C99.6026 124.121 99.5922 122.65 99.5922 122.235C99.6118 121.593 99.4717 120.956 99.1844 120.382C98.8971 119.808 98.4716 119.314 97.9462 118.944L95.9693 119.602L95.7056 126.251Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M96.3162 111.67H75.5752V102.932C75.5752 99.9372 77.6859 96.3367 80.2802 94.9058C83.9216 92.8977 87.9025 92.8624 91.202 94.8095C92.7551 95.7287 94.0428 97.0352 94.9393 98.6011C95.8357 100.167 96.3102 101.939 96.3162 103.743V111.67Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M92.9297 107.557C94.9142 103.929 93.58 99.3786 89.9496 97.3949C86.3192 95.4112 81.7674 96.7448 79.7828 100.374C77.7983 104.003 79.1325 108.552 82.7629 110.536C86.3933 112.52 90.9451 111.186 92.9297 107.557Z"
                //                             fill="#FFB8B8"/>
                //                         <path
                //                             d="M93.4775 104.352H82.7898L82.7645 104.231C82.6408 103.594 82.4809 102.965 82.2857 102.346C81.8613 102.968 81.4699 103.611 81.1132 104.274L81.0696 104.352H78.9919C78.8644 104.352 78.7383 104.325 78.6217 104.274C78.5051 104.222 78.4005 104.147 78.3147 104.053C78.2582 103.991 78.1259 103.845 78.6732 97.1675C78.6882 97.0085 78.7444 96.8561 78.836 96.7253C78.9277 96.5945 79.0518 96.4897 79.1962 96.4212C83.8857 94.233 88.6378 94.2909 93.3218 96.5942H93.4053L93.4346 96.7103C94.3587 103.818 94.2258 103.971 94.169 104.037C94.0831 104.136 93.9769 104.215 93.8576 104.27C93.7383 104.324 93.6086 104.352 93.4775 104.352Z"
                //                             fill="#2F2E41"/>
                //                         <path
                //                             d="M49.8954 82.1836L38.4968 183.646C38.4251 184.506 32.6541 184.972 32.6899 184.183L32.9767 80.9654C32.9767 80.0698 33.8011 79.3532 34.6972 79.4249L48.6408 80.6072C49.3577 80.7147 49.9312 81.3595 49.8954 82.1836Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M61.7238 130.908L56.0604 183.646C55.9887 184.506 50.2535 184.971 50.2535 184.183L50.0026 80.9651C50.0026 80.1052 61.7596 130.12 61.7238 130.908Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M56.6696 183.181L57.0997 185.474C57.5657 186.369 64.3045 189.379 64.3045 189.379C64.8063 189.558 65.4157 190.095 65.4515 190.669C65.4874 191.421 64.6629 192.03 64.0177 192.03H50.6835C49.4648 192.03 48.5328 190.74 48.7837 189.415L49.8949 183.216H56.6696V183.181Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M38.6758 183.18C38.6758 183.18 38.0664 187.73 38.389 189.091C38.7475 190.453 38.8908 192.065 35.5931 192.065C32.2954 192.065 28.0657 192.065 28.0657 192.065C28.0657 192.065 25.9509 190.417 27.8865 188.769L29.3203 188.59L31.7936 183.072L38.6758 183.18Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M41.9738 11.5684C41.9738 11.5684 36.4895 20.2386 32.009 22.7823C26.5606 25.8634 23.9081 29.9836 24.4457 34.9994C25.0193 40.0152 14.6602 42.3439 19.7501 52.5188C24.0156 61.0099 42.9775 56.0299 42.9775 56.0299L58.4265 43.5262C58.4265 43.5262 67.3877 43.3113 63.7315 33.423C62.1543 29.1237 67.5669 26.9024 65.3445 21.5642C62.6203 15.0078 57.9247 10.3145 57.9247 10.3145L41.9738 11.5684Z"
                //                             fill="#7D5034"/>
                //                         <path
                //                             d="M57.9607 37.3283C57.9607 37.3283 54.7347 20.4537 48.7128 21.7793C42.6909 23.0691 44.0888 32.1692 36.8841 34.6412C30.0019 37.0058 25.5571 42.7382 30.9338 49.5453C34.3749 53.8804 50.5767 48.0764 51.2219 48.0764C51.8671 48.0406 57.9607 37.3283 57.9607 37.3283Z"
                //                             fill="#40291B"/>
                //                         <path
                //                             d="M75.9543 67.423L74.9507 68.032C73.1584 69.1785 70.9002 68.6769 69.9683 66.993L57.7094 44.7802C56.7774 43.0605 57.4585 40.7317 59.2507 39.6211L60.2544 39.012C62.0466 37.8656 64.8066 39.7286 65.7386 41.4124L77.4956 62.228C78.4634 63.9477 77.7824 66.2765 75.9543 67.423Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M34.4822 80.1416C34.4822 80.1416 28.066 90.2807 30.5751 100.42C33.0842 110.559 33.0842 110.559 33.0842 110.559L46.8128 109.377L48.9993 83.8676L34.4822 80.1416Z"
                //                             fill="#385680"/>
                //                         <path
                //                             d="M63.9102 140.905H33.3705C30.3595 140.905 27.9579 138.361 28.173 135.352L30.5746 100.456L43.2278 77.2041H63.7668L63.9102 140.905Z"
                //                             fill="#385680"/>
                //                         <path
                //                             d="M51.5794 33.8526C47.063 33.5659 43.4785 29.625 43.6219 25.0749L43.9445 15.3299C44.0879 10.7799 47.9232 7.26882 52.4397 7.55544C56.9561 7.84205 60.5406 11.783 60.3972 16.3331L60.0746 26.0781C59.9312 30.6281 56.0959 34.1392 51.5794 33.8526Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M51.0063 50.8349C43.8373 51.3723 33.3349 42.7379 30.5031 40.8033L42.9054 36.2532C44.375 34.426 44.8052 36.2891 45.1636 36.3965C45.4862 36.2532 46.92 36.2174 48.0312 36.5398C51.0421 37.3639 55.5586 37.758 58.928 38.6895C61.3654 39.5851 61.5805 42.7021 61.5088 43.2037C61.0786 46.8581 56.3471 50.4408 51.0063 50.8349Z"
                //                             fill="#EBB85B"/>
                //                         <path
                //                             d="M45.9877 40.0148H46.597C48.4968 40.0506 50.6116 39.2624 50.6475 37.8293L50.3966 27.4394C50.4324 26.0422 48.8911 24.8599 46.9555 24.8241H46.3461C44.4463 24.7882 42.8334 25.9347 42.7975 27.332L41.9372 37.901C41.9014 39.3699 44.052 39.9789 45.9877 40.0148Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M39.7514 24.7893C40.3966 26.6523 41.8662 27.7271 42.9774 27.1897C44.0886 26.6165 44.4829 24.646 43.8377 22.783C43.1925 20.92 41.7228 19.8452 40.6116 20.3826C39.4646 20.9558 39.1062 22.9263 39.7514 24.7893Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M52.9062 10.8519C52.9062 10.8519 60.5236 13.6464 60.3802 18.7338C60.3802 18.7338 64.4482 6.87505 53.4439 6.19434L52.9062 10.8519Z"
                //                             fill="#7D5034"/>
                //                         <path
                //                             d="M52.9569 10.9069C52.9569 10.9069 44.7537 15.5263 44.8766 22.1689L38.7933 22.6746C38.7933 22.6746 35.7773 3.90128 53.4434 6.19415L52.9569 10.9069Z"
                //                             fill="#7D5034"/>
                //                         <path
                //                             d="M58.2829 78.3145L41.651 77.8487C38.0665 77.992 30.7184 76.8814 30.7184 73.9077L30.5391 47.8614C30.5033 44.8877 33.4067 42.3798 37.027 42.2365L58.032 41.4841C61.6165 41.3408 61.043 43.9562 61.0788 46.9299L63.6596 72.9046C63.6955 75.914 61.9032 78.1711 58.2829 78.3145Z"
                //                             fill="#EBB85B"/>
                //                         <path
                //                             d="M61.7235 89.2776C63.4082 88.2745 64.376 86.3398 64.1968 84.2977L62.763 67.3514C68.1039 60.6876 66.5626 54.7403 63.0856 45.8193L63.0139 42.559C63.1573 40.6244 61.9386 38.9046 60.1463 38.5105L56.3468 37.6865L54.5546 91.3914L61.7235 89.2776Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M50.2897 36.2175C50.0747 37.758 49.537 38.9045 48.2466 39.5494L50.8991 43.992C52.7989 42.8455 53.4799 40.7675 53.3365 37.2923C53.3007 36.6116 52.8347 36.0025 52.1537 35.8234L51.2576 35.5726C50.7916 35.4651 50.3256 35.7876 50.2897 36.2175Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M46.3459 44.529L48.7476 39.7282C45.414 38.7966 43.6218 38.1159 42.7256 36.4321C42.2597 35.5722 41.256 35.1781 40.3599 35.5722C39.3562 36.0021 38.8903 37.1844 39.4279 38.1876C40.5391 40.3014 42.9407 42.2719 46.3459 44.529Z"
                //                             fill="white"/>
                //                         <path
                //                             d="M33.6934 79.9977C35.7365 81.5024 33.1198 72.5456 35.0196 69.6436L34.6253 51.8734C36.5251 49.0072 36.0591 44.8154 34.1593 42.1642L33.8009 41.5551C31.7577 40.0504 27.5281 41.3043 25.6283 44.1705L21.1836 74.5519C21.1836 85.4792 33.6934 79.9977 33.6934 79.9977Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M55.3073 36.8619C55.3073 36.8619 53.4792 35.8229 53.6226 37.5785C56.0242 45.9262 56.2034 56.2086 55.1998 67.53L60.9707 56.2444L58.4258 50.2971L61.616 38.7249L55.3073 36.8619Z"
                //                             fill="#385680"/>
                //                         <path
                //                             d="M39.4281 36.8623C39.4281 36.8623 44.7331 52.2322 44.8764 63.1953C45.0198 74.1584 44.9482 91.1046 44.9482 91.1046C44.9482 91.1046 31.0404 90.5672 30.0009 85.1215C28.9973 79.7116 30.5386 40.8033 30.5386 40.8033L39.4281 36.8623Z"
                //                             fill="#294366"/>
                //                         <path
                //                             d="M55.128 67.674L50.4323 68.928C48.8552 69.2504 48.9269 70.4685 49.2495 72.2599C49.5721 74.0513 49.7513 74.9469 51.3284 74.6245L56.3467 74.1946C57.9239 73.8721 58.9634 72.1524 58.6049 70.3611C58.2823 68.5339 56.7051 67.3516 55.128 67.674Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M48.2816 68.9277L29.8934 70.2175C28.1728 70.3608 25.4845 71.6505 25.592 73.5852C25.592 73.5852 24.66 79.3892 30.7178 78.9235L49.1061 74.875C50.8266 74.7317 51.6152 73.6569 51.5077 71.7222C51.436 69.7517 50.0022 68.7485 48.2816 68.9277Z"
                //                             fill="#D48460"/>
                //                         <path
                //                             d="M38.174 36.97C38.174 36.97 39.8228 36.0027 40.0737 37.7582C42.8338 46.4284 45.1278 55.7792 44.9128 68.462L37.8155 56.4241L40.2888 49.8319L33.3708 39.4421L38.174 36.97Z"
                //                             fill="#385680"/>
                //                         <path
                //                             d="M57.5658 81.6462H71.2226C74.3411 81.6462 77.7105 79.1383 78.6783 76.0572L86.349 51.9455C87.3168 48.8643 85.5963 46.3564 82.442 46.3564H68.7851C65.6667 46.3564 62.2973 48.8643 61.3295 51.9455L53.6587 76.0572C52.6909 79.1383 54.4473 81.6462 57.5658 81.6462Z"
                //                             fill="#DDDDDD"/>
                //                         <path
                //                             d="M85.4169 61.4038L84.8793 60.3648C83.9831 58.6809 81.6532 59.6482 80.7571 62.192C80.7213 62.2995 80.6854 62.4069 80.6496 62.5144C80.0044 64.5924 80.5779 66.4912 81.94 66.742L83.4096 66.8495C84.8076 66.5629 86.7432 61.6546 85.4169 61.4038Z"
                //                             fill="#D48460"/>
                //                     </g>
                //                     <defs>
                //                         <clipPath id="clip0_8957_10399">
                //                             <rect width="219" height="192" fill="white"/>
                //                         </clipPath>
                //                     </defs>
                //                 </svg>
                //
                //             </div>
                //             <h2 className="modal-title h4">Pro/Vendor Testimonials</h2>
                //             <p>You can upload testimonial videos from past clients and projects. These videos highlight customer experiences and feedback, offering valuable insights into the quality of your services and the success of your work.</p>
                //         </div>
                //         <div className="modal__action">
                //             <div className="button-group">
                //                 <button className="button button-m button-primary modal-close" type="button"
                //                         onClick={() => setShowTestimonialModal(false)}>Close
                //                 </button>
                //             </div>
                //         </div>
                //     </div>
                // </div>
        <VideoModal videoUrl={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Pro+Vendor+-+Testimonials.mp4'} onClose={handleCloseTestimonialVideoModal} />
            )}
        </div>
    )
}


