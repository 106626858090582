import React from 'react';
import { useNavigate} from 'react-router-dom';
export default function LeadsOptions({option1, option2, navigateTo, paidChecked, freeChecked, leadTypeText="Lead type"}) {
    const navigate = useNavigate();
    return (
        <div className="homefeed--details">  
            <div className="homefeed--details__toggle">
                <legend>{leadTypeText}</legend>
                <div className="button-group">
                    <div className="form-field radio">
                        <input type="radio" id="radio-one" name="switch-one" value="yes" checked={paidChecked}
                               // onClick={(e) => { e.preventDefault(); navigate(`/${navigateTo}`)}} onChange={(e) => { e.preventDefault();}}
                        />
                        <label htmlFor="radio-one">{option1}</label>
                    </div>
                    {/*{option2 && <div className="form-field radio">*/}
                    {/*    <input type="radio" id="radio-two" name="switch-one" value="no" checked={freeChecked}*/}
                    {/*           onClick={(e) => {*/}
                    {/*               e.preventDefault();*/}
                    {/*               navigate(`/${navigateTo}`)*/}
                    {/*           }} onChange={(e) => {*/}
                    {/*        e.preventDefault();*/}
                    {/*    }}/>*/}
                    {/*    <label htmlFor="radio-two">{option2}</label>*/}
                    {/*</div>}*/}
                </div>
            </div>
        </div>
    )
};
