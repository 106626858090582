import React from 'react';
import ReactPannellum from "react-pannellum";

export default function ThreeSixtyComponent(props) {
    return(
        <ReactPannellum
            id='1'
            key={props.image}
            sceneId='firstScene'
            imageSource={`${props.image}?x-request=html`}
            config={{
                autoRotate: -3,
                autoLoad: true,
            }}
            style={{
                flexGrow: 1,
                width: '100%',
                height: '47vh',
                background: '#000000',
            }}
        />
    )
}
