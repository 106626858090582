/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { isTodayDate } from '../../utils/checkPackageValidation';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/dateFormat';
import { copyToClipBoard } from "../../utils/copyToClipBoard";
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import checkUserProfile from '../../utils/checkUserProfile';
import PayPalForm from '../libs/PayPalForm';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header';
import ModalInfo from '../libs/ModalInfo';
import NoCoverImage from '../../images/NoCoverScopeImage.png';
import PackageFilter from '../libs/PackageFilter';
import Pagination from "../libs/Pagination";
import LeadsOptionsSurveyors from "../libs/LeadsOptionsSurveyors";
import * as formValidation from '../../utils/formValidation';
import { getLatestUncompletPackage } from "../../utils/getLatestUnCompletPackage";
import AppContext from '../../AppContext';
import EmptyProMedia from "../Pros/EmptyProMedia";

export default function SurveyorPackageFeed() {
    const { userInfo, setUserInfo } = useContext(AppContext);
    const [filterValue, setFilterValue] = useState('no');
    const [isPaymentApproved, setIsPaymentApproved] = useState(null);
    const [leads, setLeads] = useState([]);
    const [originalLeads, setOriginalLeads] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showMore, setShowMore] = useState({});
    const [showLoader, setShowLoader] = useState(true);
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showAll, setShowAll] = useState(true);
    const [showLocal, setShowLocal] = useState(false);
    const [showNoPackages, setShowNoPackages] = useState(false);
    const [packages, setPackages] = useState([]);
    const [singlePackage, setSinglePackage] = useState([]);
    const [showCreditCardModal, setShowCreditCardModal] = useState(false);
    const [showFeed, setShowFeed] = useState(true);
    const [currentTab, setCurrentTab] = useState('feed');
    const createNewPackageRef = useRef(null);
    const folderName = 'cover-image';
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const s3BucketPrefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com';
    const userId = parseInt(sessionStorage.getItem('userId'));
    const userState = sessionStorage.getItem('userState');
    const userType = sessionStorage.getItem('userType');
    const [selectedFilter, setSelectedFilter] = useState('all'); // Default to 'all'
    const recordsPerPage = 30;
    // const userType = parseInt(sessionStorage.getItem('userType'));
    const userTypeToBeChecked = parseInt(process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED);
    sessionStorage.setItem('isPropertyOwner', 'false');
    // setIsPropertyOwner(false);
    const handleGoToLogin = () => navigate('/login');
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(
        parseInt(sessionStorage.getItem('currentPage')) || 1
    );

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = leads.slice(indexOfFirstRecord, indexOfLastRecord);

    useEffect(() => {
        if (isLoggedIn === 'true') {
            sessionStorage.setItem('currentPage', 1);
            getLeads();
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const typeValue = urlParams.get('type');
            if (typeValue === 'newpackage') {
                createNewPackageRef.current.click();
            }
            getSurvFeeds(userInfo.userId);
        }
    }, []);

    const resetPage = () => {
        window.scrollTo(0, 0);
    }

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const { isGoodToGo } = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (isGoodToGo) {
            getSurvFeeds(userId);
        } else {
            setShowNotAllowed(true);
        }
    }

    const handleTransactionApproval = (approved) => {
        //{ isApproved: true, additionalData: { packageName: paymentResponse.data.packageName } }
        const packageName = approved?.additionalData?.packageName;
        if (approved?.isApproved) {
            setIsPaymentApproved(packageName);
            navigate(`/newpackage/${packageName}`);
        }
    };

    const makePackageInvalid = (pckId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/makePackageInvalid`, { packageId: pckId }).then((response) => {
            if (typeof response.data !== 'undefined' && response.data.affectedRows === 1) {
                console.log('package updated');
            } else {
                console.log('no package found');
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    const getSurvFeeds = (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/allpackages`, { userId: userId, userType: userType }).then((response) => {
            const tempPackages = [];
            if (response.data.length > 0) {
                response.data.map((pck) => {
                    if (!isTodayDate(pck.packageExpirationDate)) {
                        tempPackages.push(pck);
                    } else {
                        makePackageInvalid(pck.packageId);
                    }
                });
                if (tempPackages.length > 0) {
                    setShowNoPackages(false);
                    setPackages(tempPackages);
                }
            } else {
                setShowNoPackages(true);
            }
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            console.log('tags error = ', error);
        })
    }

    const getPackageDescription = (packageDesc, index) => {
        if (!showMore[index]) {
            if (packageDesc.trim().length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH) {
                return `${packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH)}... `;
            } else {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
            }
        } else {
            return packageDesc;
        }
    }

    const handleReadMore = (index) => {
        setShowMore({ ...showMore, [index]: !showMore[index] });
    }

    const deletePackage = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deletePackage`, { packageName: singlePackage.packageName, userId: userId, packageAddress: `${singlePackage.packageAddress}${singlePackage.packageAddress2.length > 0 ? singlePackage.packageAddress2 + ',' : ','} ${singlePackage.packageCity}, ${singlePackage.packageState} ${singlePackage.packageZip}`, packageId: singlePackage.packageId }).then((response) => {
            if (typeof response.data !== 'undefined' && response.data.affectedRows === 1) {
                const tempPackages = packages.filter((pck) => {
                    return pck.packageId !== singlePackage.packageId;
                });
                setShowDeleteModal(false);
                setPackages(tempPackages);
            } else {
                console.log('no package found');
            }
        }).catch(function (error) {
            console.log(error);
        })
    };

    const getLeads = async () => {
        try {
            setShowLoader(true);
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/getSurveyorLeads`).then((response) => {
                if (response.data.length > 0) {
                    setOriginalLeads(response.data);
                    setLeads(response.data);
                }
                setShowLoader(false);
            }).catch(function (error) {
                setShowLoader(false);
                console.log(error);
            })
        } catch (error) {
            setShowLoader(false);
        }
    }

    /*useEffect(() => {
        const fetchUser = () => {
            fetch(`${process.env.REACT_APP_LOCALHOST}/user/${userId}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then((response) => {
                    setUserInfo(response);
                });
        };

        fetchUser();
    }, []);*/

    const createPackage = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        /* fetch(`${process.env.REACT_APP_LOCALHOST}/paypal/client-token`, {
             method: "GET",
             headers: {
                 'Content-Type': 'application/json'
             }
         }).then((response) => response.json())
             .then((response) => {
                 const { client_token } = response;
                 setClientToken(client_token);
                 setShowCreditCardModal(true);
             });*/
        const result = await getLatestUncompletPackage(userId);
        if (result?.packageName) {
            setIsPaymentApproved(result.packageName);
            navigate(`/newpackage/${result.packageName}`);
        } else {
            setShowCreditCardModal(true);
        }
        //toggleCreditCardModal();
    }

    const toggleCreditCardModal = () => {
        // setShowCreditCardModal(!showCreditCardModal);
        if (isPaymentApproved) {
            navigate('/newpackage/:packageId');
        }
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/checkForOpenBalance`, { userId: userId, serviceType: 2 }).then((response) => {
            if (response.data.length > 0) {
                sessionStorage.setItem('transactionCode', response.data[0].transaction_code);
                navigate('/newpackage');
            } else {
                setShowCreditCardModal(!showCreditCardModal);
                if (isPaymentApproved) {
                    navigate('/newpackage');
                }
            }
        }).catch(function (error) {
            console.log('Error checking for balance = ', error);
        })
    };

    const filterLeads = (type) => {
        setCurrentPage(1); // Reset pagination
        setSelectedFilter(type); // Update selected filter type

        if (type === 'all') {
            setLeads([...originalLeads]);
        } else { // Assuming 'local' means filtering by 'userState'
            const localLeads = originalLeads.filter((lead) => lead.state === userState);
            setLeads(localLeads);
        }
    };

    const updateFeed = (e) => {
        setFilterValue(e.target.value);
        if (e.target.value === 'no') {
            packages.sort((a, b) => {
                const dateA = new Date(a.packageCreated);
                const dateB = new Date(b.packageCreated);

                // Compare the dates in reverse order (descending)
                return dateB - dateA;
            });
        } else if (e.target.value === 'on') {
            packages.sort((a, b) => {
                const dateA = new Date(a.packageCreated);
                const dateB = new Date(b.packageCreated);

                // Compare the dates in reverse order (descending)
                return dateA - dateB;
            });
        }
    }

    const handleCopyToClipboard = (textToCopy, type) => {
        if (type === 0) {
            copyToClipBoard(textToCopy);
        } else if (type === 2) {
            const cleaned = ('' + textToCopy).replace(/\D/g, '');
            const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                const formattedNumber = ['(', match[2], ') ', match[3], '-', match[4]].join('');
                copyToClipBoard(formattedNumber); // Copy to clipboard
                return formattedNumber; // Return the formatted number
            } else {
                return textToCopy; // Return the original phone number if it doesn't match the pattern
            }
        } else {
            const address = `${textToCopy.address}${textToCopy.address2 && textToCopy.address2.length > 0 ? ', ' + textToCopy.address2 : ''}, ${textToCopy.city}, ${textToCopy.state} ${textToCopy.zip}`;
            copyToClipBoard(address);
        }
    };
    

    return (
        <div id="fazzad-site-container">
            <Header />
            {showCreditCardModal &&
                <PayPalForm
                    modalHeader='Create new package'
                    amountPaid={parseInt(process.env.REACT_APP_COST_TO_CREATE_PACKAGE) * 100}
                    onClose={() => setShowCreditCardModal(false)}
                    userId={userId}
                    serviceType={2}
                    userInfo={userInfo}
                    onTransactionApproval={handleTransactionApproval}
                    packageName={isPaymentApproved}
                />
            }
            {showNotAllowed ?
                <ModalInfo
                    messageHeader={"You are not allowed to see this page."}
                    messageBody={"This page is just for registered surveyors."}
                    messageType='error'
                />
                : ''
            }
            <main id="fazzad-main">
                <div id="app-container">
                    <div id="homefeed-title">
                        {/* <h1 className="h3">Welcome to Fazzad!</h1> */}
                        <div className="button-group">
                            <button
                                type="button"
                                className="button-l button-primary"
                                onClick={(e) => { createPackage(e) }} ref={createNewPackageRef}
                            >
                                {getSvg('plus')}
                                <span className="text">Create New Package</span>
                            </button>
                            <button
                                type="button"
                                className="button-l button-tertiary"
                                onClick={() => { navigate(`/courses/${userId}`) }}
                            >
                                {getSvg('grid')}
                                <span className="text">Courses</span>
                            </button>
                        </div>
                    </div>

                    <div id="homefeed-container">
                        <div className="tab-container tab-style--underline">
                            <ul>
                                <li className={`${currentTab === 'feed' ? "current" : ""}`}>
                                    <a href="/" onClick={(e) => { e.preventDefault(); setShowFeed(true); setCurrentTab('feed') }} className="tab">
                                        <span className="text">Feed</span>
                                    </a>
                                </li>
                                {/*TODO: Delete this tab*/}
                                {/*<li className={`${currentTab === 'leads' ? "current" : ""}`}>*/}
                                {/*    <a href="/" onClick={(e) => { e.preventDefault(); setShowFeed(false); getLeads(); setCurrentTab('leads') }} className="tab">*/}
                                {/*        <span className="text">Leads</span>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>

                        {showFeed && (
                            <PackageFilter
                                numberOfPackages={packages.length}
                                pFirst={'Viewing'}
                                pSecond={'As-Built Packages.'}
                                sortByText={'Sort by'}
                                filterValue={filterValue}
                                updateFeed={updateFeed}
                            />
                        )}
                        {showLoader && <span className="loader loader--l"></span>}
                        {showFeed ? (
                            packages.length > 0 ? (
                                <div id="homefeed">
                                    {packages.map((pck, index) => (
                                        <div className="homefeed-item" key={index}>
                                            <div className="homefeed-item__content">
                                                <h2 className="h4 package-name">{`${pck.packageAddress}${pck.packageAddress2.length > 0 ? ` ${pck.packageAddress2},` : ','} ${pck.packageCity}, ${pck.packageState} ${pck.packageZip}`}</h2>
                                                <div className="package-scope">
                                                    <h3 className="h5">Scope of work</h3>
                                                    <p className="preformatted-text">{getPackageDescription(pck.scopeOfWork, index)}
                                                        {pck.scopeOfWork.trim().length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH && (
                                                            <>
                                                                <br />
                                                                <a href="/" onClick={(e) => { e.preventDefault(); handleReadMore(index); e.target.blur(); }}>
                                                                    {showMore[index] ? 'Read less' : 'Read more'}
                                                                </a>
                                                            </>
                                                        )}
                                                    </p>
                                                    <p><small>This package expires on {formatDate(pck.packageExpirationDate)}.</small></p>
                                                </div>
                                                <div className="button-group">
                                                    <button
                                                        type="button"
                                                        className="button-m button-tertiary"
                                                        onClick={() => {
                                                            sessionStorage.setItem('packageName', pck.packageName);
                                                            navigate(`/survdetails/${pck.packageName}`)
                                                        }}
                                                    >
                                                        <span className="text">View</span>
                                                        {getSvg('chevron-right')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="homefeed-item__image">
                                                <img
                                                    onError={(e) => e.target.src = NoCoverImage}
                                                    className='rounded' src={pck.coverImage !== null ? `${s3BucketPrefix}/${pck.packageName}/${folderName}/${pck.coverImage}` : NoCoverImage} alt="" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                showNoPackages && !showLoader && (
                                    // <AlertMessage
                                    //     messageHeader={"You have not created any packages yet."}
                                    //     messageBody={""}
                                    //     startDate={null}
                                    //     setShowWarningContainerMessage={''}
                                    //     messageType={"informational"}
                                    //     showButton={false}
                                    // />

                                    <EmptyProMedia
                                        proMediaType={'feed'}
                                        title={'Package feed'}
                                        description={'Download your As-Built Survey package anytime and view files by clicking on any folder. Pros and vendors may see the package and reach out if they\'re interested in your project.'}
                                        typeName={'Feed'}
                                    />
                                )
                            )
                        )
                            :
                            <>
                                <div className="package-files-container">
                                    <div className="package-files__list">


                                        <div className="table-container">
                                            <fieldset className="border homefeed--interactive surveyor--free-lead">
                                                <legend>Lead type</legend>
                                                <LeadsOptionsSurveyors
                                                    option1="All"
                                                    option2="Local"
                                                    showAll={showAll}
                                                    showLocal={showLocal}
                                                    setShowAll={setShowAll}
                                                    setShowLocal={setShowLocal}
                                                    filterLeads={filterLeads}
                                                    leadTypeText={""}
                                                />
                                            </fieldset>
                                            <table className="surveyor--leads-table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Leads<span>
                                                                {/* <a 
                                                        href="#" 
                                                        onClick={(e) => {e.preventDefault(); filterLeads('all');}}
                                                        className={selectedFilter === 'all' ? 'selected-filter' : ''}
                                                        >
                                                        All
                                                        </a>
                                                        &nbsp;&nbsp;&nbsp; 
                                                        <a 
                                                        href="#" 
                                                        onClick={(e) => {e.preventDefault(); filterLeads('local');}}
                                                        className={selectedFilter === 'local' ? 'selected-filter' : ''}
                                                        >
                                                        Local
                                                        </a> */}
                                                                {selectedFilter === "local" && (<span style={{ fontSize: "10pt" }}> &nbsp; - {sessionStorage.getItem('userState')}</span>)}
                                                            </span>
                                                        </th>
                                                        <th className="col-date">Date</th>
                                                    </tr>
                                                </thead>
                                                {leads.length > 0 ?
                                                    <tbody>
                                                        {currentRecords.map((lead, index) => {
                                                            return (
                                                                <tr key={`tr_${index}`}>
                                                                    <td key={`td_${index}`} className="fz-break-all">
                                                                        <p>
                                                                            <strong>{lead.firstName} {lead.lastName}</strong><br />
                                                                            {lead.address}{lead.address2.length > 0 ? ` ${lead.address2}` : ''}
                                                                            &nbsp;{lead.city}, {lead.state} {lead.zip}&nbsp;<button
                                                                                title="Copy!"
                                                                                className="button button-icon button-xs"
                                                                                onClick={() => handleCopyToClipboard(lead, 1)}
                                                                            >
                                                                                {getSvg('copy')}
                                                                            </button><br />
                                                                            {lead.email}&nbsp;<button
                                                                                title="Copy!"
                                                                                className="button button-icon button-xs"
                                                                                onClick={() => handleCopyToClipboard(lead.email, 0)}
                                                                            >
                                                                                {getSvg('copy')}
                                                                            </button><br />
                                                                            
                                                                             {formValidation.formatPhoneNumber(lead.phone, 0)}&nbsp;<button
                                                                                title="Copy!"
                                                                                className="button button-icon button-xs"
                                                                                onClick={() => handleCopyToClipboard(lead.phone, 2)}
                                                                            >
                                                                                {getSvg('copy')}
                                                                            </button><br />
                                                                        </p>
                                                                    </td>
                                                                    <td>{formatDate(lead.createdDate)}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                    : ''}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="fz-block-sm">
                                    {recordsPerPage < leads.length && (
                                        <Pagination
                                            recordsPerPage={recordsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            totalNumberOfRecords={leads.length}
                                            resetPage={resetPage} />
                                    )}
                                </div>
                            </>
                        }
                        {showDeleteModal && (
                            <div id="modal-5" className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_PACKAGE_MESSAGE}</h2>
                                    </div>
                                    <div className="modal__action">
                                        <div className="button-group">
                                            <button
                                                className="button button-m button-secondary"
                                                type="button"
                                                onClick={() => setShowDeleteModal(false)}
                                            >
                                                No, Cancel
                                            </button>
                                            <button
                                                className="button button-m button-destructive"
                                                type="button"
                                                onClick={deletePackage}
                                            >
                                                Yes, Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </main>
        </div>
    )
}
