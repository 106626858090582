import Axios from 'axios';
const checkUserProfile = async (userId, userType, userTypeToBeChecked) => {
    let isValid = true;
    return Axios.post(`${process.env.REACT_APP_LOCALHOST}/getUserProfile`, { userId: userId }).then((response) => {
        if (response.data.length > 0) {
            let compareStr = `${parseInt(response.data[0].userType)} === ${parseInt(userType)} && ${parseInt(response.data[0].userId)} === ${parseInt(userId)} && ${parseInt(response.data[0].userType)} === ${parseInt(userTypeToBeChecked)}`
            if (typeof userTypeToBeChecked === 'object') {
                compareStr = userTypeToBeChecked.includes(parseInt(userType));
            } else {
                userTypeToBeChecked = `${parseInt(response.data[0].userType)} === ${parseInt(userTypeToBeChecked)}`;
            }
            if (parseInt(response.data[0].userType) === parseInt(userType) && parseInt(response.data[0].userId) === parseInt(userId) && eval(compareStr)) {
                isValid = true
            } else {
                isValid = false;
            }
        } else {
            isValid = false;
        }
        return { isGoodToGo: isValid, user: response.data[0] };
    }).catch(function (error) {
        console.log('tags error = ', error);
    })
}

export default checkUserProfile;
