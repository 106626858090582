import axiosInstance from "./axiosInstance";
import axios from "axios";
import {cleanMediaUrl} from "./clearMediaUrl";
import JSZip from "jszip";

async function getAccessToken() {
    return axiosInstance.get(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/get-access-token`)
}

async function getUploadSignedUrl(bucketKey, fileName) {
    return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/get-upload-signed-url`, {
        bucketKey: bucketKey,
        fileName: fileName
    })
}

async function finishUpload(bucketKey, fileName, uploadKey) {
    return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/finish-upload`, {
        bucketKey: bucketKey,
        fileName: fileName,
        uploadKey: uploadKey
    })
}

async function translateFiles(urn) {
    return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/translate-files`, {
        urn: urn
    });
}

async function translateCompressedFiles(urn, rootFilename) {
    return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/translate-files`, {
        urn: urn,
        rootFilename: rootFilename,
        isCompressed: true
    });
}

async function translateRevitFiles(urn) {
    return axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/translate-files`, {
        urn: urn,
        generateMasterView: true
    });
}

async function checkStatus(urn) {
    return axiosInstance.get(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/check-status`, {
        params: {
            urn: urn
        }
    });
}

async function deleteBucket(bucketKey) {
    return axiosInstance.delete(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/delete-bucket/${bucketKey}`);
}

async function deleteBucketFile(bucketKey, fileName) {
    return axiosInstance.delete(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/delete/bucket/${bucketKey}/file/${fileName}`);
}

async function deleteFileTranslation(urn) {
    return axiosInstance.delete(`${process.env.REACT_APP_LOCALHOST}/autodesk-viewer/delete-file-translation/${urn}`);
}

function getUrn(bucketKey, fileName) {
    return Buffer.from(
        `urn:adsk.objects:os.object:${bucketKey}/${fileName}`,
    ).toString("base64")
}

function getFileNameFromFilePath(path, bucketKey, fileName) {
    const originalString = path;
    const regex = new RegExp(`^(${bucketKey}\/)?${fileName}\/`);

    return cleanMediaUrl(originalString.replace(regex, ''));
}

async function uploadWithSignedUrl(signedUrl, uploadKey, fileData, onProgress = null, onLoad = null) {
    await axios({
        method: 'put',
        url: signedUrl,
        data: fileData,
        headers: {
            'Content-Type': fileData.type,
        },
        onUploadProgress: (progressEvent) => {
            if (onProgress && progressEvent.total) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onProgress(percentCompleted);
            }
        }
    })

    onLoad?.();
}

async function getObjFilenameFromZip(file) {
    try {
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(file);

        let foundObjFile = null;
        zip.forEach((relativePath, file) => {
            if (file.name.endsWith(".obj")) {
                foundObjFile = file.name.split("/").pop();
            }
        });

        return foundObjFile;
    } catch (err) {
        return null
    }
}

export const AutodeskViewerHelpers = {
    getAccessToken,
    getUploadSignedUrl,
    uploadWithSignedUrl,
    finishUpload,
    translateFiles,
    translateCompressedFiles,
    translateRevitFiles,
    checkStatus,
    getUrn,
    deleteBucket,
    deleteBucketFile,
    deleteFileTranslation,
    getFileNameFromFilePath,
    getObjFilenameFromZip
}
