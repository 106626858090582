import React from 'react';
import getSvg from '../../utils/getSvg';

const VideoModal = ({ onClose, poster, videoUrl }) => {
    return (
        <div className="modal-container">
            <div className="modal--video">
                <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={onClose}>
                    {getSvg('close')}
                </button>
                <video controls width='100%' height='auto' poster={poster} autoPlay>
                    <source src={videoUrl} type='video/mp4' />
                    <p>Your browser does not support the video element.</p>
                </video>
            </div>
        </div>
    );
};

export default VideoModal;
