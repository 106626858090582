import React, {useState, useEffect} from 'react';
import styles from './tabbed-carousel.module.scss';

import getSvg from "../../../../../utils/getSvg";
import {LoadingSpinner} from "../../page-select-modal/loading-spinner/loading-spinner";

function TabbedCarouselComponent({stillImages, images360, setCurrentImg, activeTab, setActiveTab, loading}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const bucketPrefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com';
  const images = activeTab === 'STILL_IMAGE' ? stillImages : images360;

  useEffect(() => {
    setCurrentImg(images[currentIndex]);
  }, [currentIndex, images, setCurrentImg]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  if (loading) return <div className={styles['carousel-container']}><LoadingSpinner/></div>

  return (
      <div className={styles['carousel-container']}>
        <div className={styles.tabs}>
          <button
              className={activeTab === 'STILL_IMAGE' ? styles.active : ''}
              onClick={() => {
                setActiveTab('STILL_IMAGE');
                setCurrentIndex(0);
              }}
          >
            Still pictures
          </button>
          <button
              className={activeTab === '360_IMAGE' ? styles.active : ''}
              onClick={() => {
                setActiveTab('360_IMAGE');
                setCurrentIndex(0);
              }}
          >
            360 pictures
          </button>
        </div>
        {images[currentIndex]?.Key && <div className={styles['image-container']}>
          <img src={`${bucketPrefix}/${images[currentIndex]?.Key}`} alt={`${activeTab} ${currentIndex + 1}`}/>
          {Object.keys(images).length > 1 && <>
            <button className={`${styles.arrow} ${styles['left-arrow']}`} onClick={handlePrev}>
              {getSvg('chevron-left')}
            </button>
            <button className={`${styles.arrow} ${styles['right-arrow']}`} onClick={handleNext}>
              {getSvg('chevron-right')}
            </button>
          </>}
        </div>}
        {!Object.keys(images || {}).length &&
            <p className={styles['no-images-message']}>No images available for this category.</p>}
      </div>
  );
}

export default TabbedCarouselComponent;
